import { useAuth0 } from '@auth0/auth0-react';
import { Button, Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useStyles } from './styles';

const Authorization: React.FC = () => {
  const classes = useStyles();
  const { loginWithPopup } = useAuth0();

  return (
    <Container className={classes.container} maxWidth="lg">
      <Container
        className={classes.innerContainer}
        component="main"
        maxWidth="xs">
        <div className={classes.circle}>
          <Typography align="center" className={classes.mainTitle} variant="h5">
            Kandidatenbeheer
          </Typography>
        </div>
        <Paper className={classes.info}>
          <Typography className={classes.title} variant="h6">
            Welkom!
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Log in om aan de slag te gaan.
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => loginWithPopup()}>
            Inloggen
          </Button>
        </Paper>
      </Container>
    </Container>
  );
};

export default Authorization;
