import axios from 'axios';


if (process.env.NODE_ENV === 'development') {
  window.REACT_APP_AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
}

export const requestJsonLp = (token: string) => axios.create({
  baseURL: `${window.REACT_APP_AUTH_AUDIENCE}/api`,
  timeout: 30000,
  headers: { Authorization: `Bearer ${token}`, Accept: 'application/ld+json' }
});

export const request = (token: string) => axios.create({
  baseURL: `${window.REACT_APP_AUTH_AUDIENCE}/api`,
  timeout: 30000,
  headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
});

export const requestBare = (token: string) => axios.create({
  baseURL: `${window.REACT_APP_AUTH_AUDIENCE}`,
  timeout: 30000,
  headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
});

export const urlWithParam = (url: string, properties = []): string => {
  const params = Object.entries(properties)
    .filter((e) => typeof e[1] === 'string' && e[1] && e[1] !== "all")
  return params ? `${url}?${params}` : url;

};
