import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: '25px 42px',
      backgroundColor: '#F0F0F0',
      maxWidth: 454,
    },
    container: {
      width: '100vw'
    },
    form: {
      backgroundColor: '#F0F0F050',
      border: '1px solid #F0F0F0',
      width: '100%',
      padding: 20,
      borderRadius: 6,
      flexDirection: 'column',
      display: 'flex'
    },
    textInput: {
      marginTop: 30,
      width: '100%'
    },
    formSection: {
      marginTop: '3rem',
      fontWeight: 700,
      fontSize: 18
    },
    agentLoginEmail:{
      fontSize: 14
    },
    removeEmailContainer:{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    submitButton:{
      marginTop: '2rem',
      padding: '10px',
      fontWeight: 700,
      background: '#33333360'
    }
  }),
);
