import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > button': {
        width: '100%',
        display: 'block',
        textAlign: 'left',
      },
    },
  }),
);
