import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setUserToken } from '../../../store/actions/userActions';
import { useAppSelector } from '../../../store/store';
import { getAgentsThunk } from '../../../store/thunks/agentsThunk';
import { useStyles } from './styles';

 

export default function AdminHome() {
    const classes = useStyles();
    const history = useHistory();
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const { data } = useAppSelector((state) => state.agents);
  
    useEffect(() => {
      const getUserToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(setUserToken({ token: accessToken }))
          dispatch(getAgentsThunk(accessToken))
         
        } catch (e: any) {
          console.log(e.message);
        }
      };
      getUserToken();
    }, [dispatch]);
  return (
    <div>
         <Grid container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="h4">
                Klanten
              </Typography>
            </Grid>
            <Grid item xs={2}>
             <Button
             variant="contained"
             href="/admin/agents/new"
             >
                 Klant toevoegen
             </Button>
            </Grid>
          </Grid>
          <div className={classes.cardContainer}>
              {data['hydra:member'].map((agent) => 
              <Card 
              onClick={() => history.push(`/admin/agents/${agent.id}`)}
              className={classes.agentCard}
              variant="outlined"
               key={agent.id}>
                   <h4>{agent.details.name || agent.name}</h4>
               </Card>
              )}
          </div>
    </div>
  )
}
