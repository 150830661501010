import { CandidateFormValues } from '../types/candidates';
import { request } from '../utils/request';

export const setCandidateData = async (
  token: string,
  id: string,
  values: CandidateFormValues,
): Promise<any> => {
  await request(token).put(`/candidates/${id}`, values);
};
