import { useAuth0 } from '@auth0/auth0-react';
import { Button, Fade, Modal, Paper, Typography } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Search from '@material-ui/icons/Search';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import React from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../store/store';
import {
  changeCandidateStatusThunk,
  requestExtraInfoThunk,
} from '../../../../../store/thunks/candidatesThunk';
import { CandidateStatusTransitionNames } from '../../../../../store/types/candidates';
import { useStyles } from './styles';

type Props = {
  candidatesIds: Array<string>;
  isOpen: boolean;
  handleClose: () => void;
};

export const SetStatusModal: React.FC<Props> = ({
  candidatesIds,
  isOpen,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = useAppSelector((state) => state.user);

  const handleStatusChange = (status: CandidateStatusTransitionNames) => {
    dispatch(changeCandidateStatusThunk(token, { ids: candidatesIds, status }));
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onBackdropClick={handleClose}
      disableAutoFocus
      BackdropProps={{ style: { opacity: 0 } }}>
      <Fade in={isOpen}>
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.title} align="center">
            Kies een status
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              startIcon={<Search className={classes.icon} />}
              variant="outlined"
              onClick={() =>
                handleStatusChange(CandidateStatusTransitionNames.reset)
              }>
              Te beoordelen
            </Button>
            <Button
              startIcon={<ThumbUpAltOutlinedIcon className={classes.icon} />}
              variant="outlined"
              onClick={() =>
                handleStatusChange(CandidateStatusTransitionNames.promote)
              }>
              Geschikt
            </Button>
            <Button
              startIcon={<ThumbDownAltOutlinedIcon className={classes.icon} />}
              variant="outlined"
              onClick={() =>
                handleStatusChange(CandidateStatusTransitionNames.devote)
              }>
              Niet geschikt
            </Button>
            <Button
              startIcon={<CloseOutlinedIcon className={classes.icon} />}
              variant="outlined"
              onClick={() =>
                handleStatusChange(CandidateStatusTransitionNames.reject)
              }>
              Afgewezen
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};
