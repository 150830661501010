import {
  AgentActions,
  AgentsDetailsResponse,
  AgentsResponse,
  GET_AGENTS_DATA,
  GET_AGENTS_DATA_ERROR,
  GET_AGENTS_DATA_LOADING,
  GET_AGENTS_DETAILS_DATA,
  SET_LOGIN,
  SET_LOGINS_FROM_API
} from '../types/agents';

export const RECIEVE_ADS_DATA = 'RECIEVE_ADS_DATA';

export const getAgentsDataLoading = (): AgentActions => {
  return {
    type: GET_AGENTS_DATA_LOADING,
  };
};

export const getAgentsData = (payload: AgentsResponse): AgentActions => {
  return {
    type: GET_AGENTS_DATA,
    payload,
  };
};

export const getAgentsDetailsData = (payload: AgentsDetailsResponse): AgentActions => {
  return {
    type: GET_AGENTS_DETAILS_DATA,
    payload,
  };
};

export const getAgentLogins = (payload: any): AgentActions => {
  return {
    type: SET_LOGIN,
    payload,
  };
};

export const getAgentsDataError = (payload: any): AgentActions => {
  return {
    type: GET_AGENTS_DATA_ERROR,
    payload,
  };
};

export const setAgentLoginsFromApi = (payload: any): AgentActions => {
  return {
    type: SET_LOGINS_FROM_API,
    payload,
  };
};
