import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../store/store';
import {
  changeCandidateStatusThunk,
  getCandidateProfileThunk,
  getCandidatesThunk,
} from '../../../../../store/thunks/candidatesThunk';
import { CandidateStatusTransitionNames } from '../../../../../store/types/candidates';
import { EditCandidate } from '../EditCandidate/EditCandidate';
import { ProfileInfo } from '../ProfileInfo/ProfileInfo';

export const ProfileTab: React.FC = () => {
  const { data: candidate, isLoading } = useAppSelector(
    (state) => state.candidates.candidate,
  );
  const { getAccessTokenSilently } = useAuth0();
  const { editCandidateOpen } = useAppSelector((state) => state.ui);

  const dispatch = useDispatch();

  const { id: leadId, candidateId } = useParams() as {
    id: string;
    candidateId: string;
  };

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(getCandidatesThunk(accessToken, leadId));
        dispatch(getCandidateProfileThunk(accessToken, candidateId));
      } catch (e: any) {
        console.log(e.message);
      }
    };
    getUserToken();
    
  }, [dispatch, candidateId, leadId]);

  if (isLoading) {
    return (
      <Container maxWidth="lg">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      {editCandidateOpen ? (
        <EditCandidate data={candidate} />
      ) : (
        <ProfileInfo data={candidate} />
      )}
    </>
  );
};
