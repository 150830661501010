import { useFormik } from 'formik';
import React from 'react';
import { StringMappingType } from 'typescript';

import { CandidateFormValues } from '../../../../../store/types/candidates';
import CandidateForm from '../../../../components/CandidateForm/CandidateForm';
import { validationSchema } from './validation';

interface Props {
  handleSubmit: (values: CandidateFormValues) => void;
  agentDetails: any
}

export const EnrichingFormComponent: React.FC<Props> = ({
  handleSubmit,
  agentDetails
}) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone:  '',
      birthday: null,
      gender: '',
      employment: '',
      income: 0,
      guarantor: '',
      householdType: '',
      household: '',
      pets: null,
      availableSince: null,
      term: null,
      info: false,
      terms: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: ({ info, terms, pets, ...values }) => {
      const updatedValues = {
        ...values,
        pets: typeof pets === 'string' ? pets === 'true' : null,

      };
      handleSubmit(updatedValues);
    },
  });


  return (
    <CandidateForm
      creation
      handleCloseForm={() => console.log('Closing Form')}
      values={formik.values}
      errors={formik.errors}
      handleSubmit={formik.handleSubmit}
      handleChange={formik.handleChange}
      isValid={formik.isValid}
      agentDetails={agentDetails}
    />
  );
};
