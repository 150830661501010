import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    navButtonContainer: {
      margin: '22px 0 16px',
      '& > button': {
        marginRight: 8,
      },
    },
    textIconsContainer: {
      flexDirection: 'row',
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    statusButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 12,
    },
    candidateButtonsBlock: {
      textAlign: 'right',
    },
    mainCandidateInfo: {
      padding: '26px 20px',
      marginBottom: 40,
    },
    candidateName: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    candidateDescr: {
      fontSize: 14,
      marginBottom: 12,
    },
    txtWithIco: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      fontSize: 14,
      '& :first-child': {
        marginRight: 8,
      },
    },
    candidateSource: {
      marginTop: 36,
    },
  }),
);
