export default {
  'enriching.title': 'Thanks for your interest in {address}',
  'enriching.body':
    'We would like to get to know you better so we can assess whether you are a fitting candidate for this listing. Your information is only shared with the agent in a secure environment.{br} At any given moment you can ask us to delete your data. ',
  'enriching.form.profile': 'Profile',
  'enriching.form.firstName': 'First name*',
  'enriching.form.lastName': 'Last name*',
  'enriching.form.contact': 'Contact details',
  'enriching.form.birthday': 'Date of birth',
  'enriching.form.gender': 'Prefix',
  'enriching.form.email': 'E-mail*',
  'enriching.form.phone': 'Phone number',
  'enriching.form.additional': 'Additional features',
  'enriching.form.employment': 'Employment',
  'enriching.form.income': 'Gross month income household',
  'enriching.form.guarantor': 'Guarantor',
  'enriching.form.household': 'Household composition',
  'enriching.form.members': 'Amount of household members',
  'enriching.form.pet': 'Pet',
  'enriching.form.yes': 'Yes',
  'enriching.form.no': 'No',
  'enriching.form.period': 'Rental duration',
  'enriching.form.startDate': 'Preffered start date',
  'enriching.form.info':
    'I am aware that my information is shared with {agent}*',
  'enriching.form.terms': 'I agree with the privacy policy*',
  'enriching.form.submit': 'Share information',
  'enriching.form.disclamer': 'Data is stored in a secure environment',
  'enriching.form.gender.MALE': 'Sir',
  'enriching.form.gender.FEMALE': 'Madam',
  'enriching.form.gender.UNSET': 'Would rather not say',
  'enriching.form.employment.EMPLOYED_BY_EMPLOYER': 'Employed by employer',
  'enriching.form.employment.ENTREPRENEUR': 'Entrepreneur or freelancer',
  'enriching.form.employment.STUDENT': 'Student',
  'enriching.form.employment.RETIRED': 'Retired',
  'enriching.form.employment.UNEMPLOYED': 'Unemployed',
  'enriching.form.guarantor.IN_NETHERLANDS':
    'Guarantor living in the Netherlands',
  'enriching.form.guarantor.ABROAD': 'Guarantor living abroad',
  'enriching.form.guarantor.NO_GUARANTOR': 'No guarantor',
  'enriching.form.household.SINGLE': 'Single',
  'enriching.form.household.WITH_PARTNER': 'Living together with partner',
  'enriching.form.household.WITH_FAMILY': 'Living together with family',
  'enriching.form.household.WITH_FRIENDS': 'Living together with friend(s)',
  'enriching.form.rentalPeriod.ZERO_THREE': '0 - 3 months',
  'enriching.form.rentalPeriod.THREE_SIX': '3 - 6 months',
  'enriching.form.rentalPeriod.SIX_TWELVE': '6 - 12 months',
  'enriching.form.rentalPeriod.YEAR': '1 - 2 years',
  'enriching.form.rentalPeriod.TWO_YEARS': '2 years +',
  'enriching.form.rentalPeriod.UNDEFINED': 'Indefinite period',
  'enriching.form.rentalPeriod.INDEFINITE': 'Indefinite period',
  'enriching.form.error.fields': 'Please fill out the required fields',
  'enriching.form.error.checkboxes':
    'Please confirm that you agree with our terms',
    'adoverview.status.available': 'Available',
    'adoverview.status.option': 'Onder option',
    'adoverview.status.rented': 'Rented'
};
