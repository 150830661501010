import {
  CandidateStatus,
  MessagesStatus,
  SortDirection,
} from '../../types/entityTypes';

export type Lead = {
  city: string;
  candidates: Array<Candidate>;
  totalAmountCandidates: number;
  houseNumber: string;
  id: string;
  details: ListingDetails;
  numberOfLeads: number;
  numberOfUnassessedLeads: number;
  numberOfLeadsToAssess: number;
  numberOfRejectedLeads: number;
  numberOfSuitableLeads: number;
  numberOfNotSuitableLeads: number;
  street: string;
  settings: ListingSettings;
  description: string;
  imageUrl: string;
  link: string;
  originId: string;
  originName: string;
status: string,
  postalCode: string;
  price: number;
  rooms: number;
  surface: number;
  messages: Array<any>;
};

export interface AgentDetails {
  url: string | null;
  logo: string | null;
  name: string;
  privacyPolicy: string;
}
export interface ListingDetails {
  city: string;
  link: string;
  enrichment: string;
  price: number;
  rooms: number;
  surface: number | null;
  imageUrl: string | null;
  listingId: string | null;
  postalCode: string;
  description: string;
  houseNuber: string;
  originName: string;
  originId: string;
  agentDetails: AgentDetails;
  showHouseNumber: boolean;
}

export interface ListingSettings {
  '@id': string;
  '@type': string;
  city: string;
  description: string;
  details: ListingDetails;
  enrichment: string;
  houseNumber: string;
  imageUrl: null | string;
  link: string;
  listingId: string;
  originId: null | string;
  originName: string;
  postalCode: string;
  price: number;
  rooms: null | number;
  showHouseNumber: boolean;
  street: string;
  streetWithHouseNumber: string;
  surface: null | number;
}
export interface Listing {
  id: string;
  details: ListingDetails;
}

export interface Candidate {
  email: string;
  firstName: string;
  household: string;
  householdType: string;
  id: string;
  income: number;
  lastName: string;
  guarantor: string;
  locale: string;
  phone: string;
  messages: Array<Message>;
  status: CandidateStatus;
  notes: Array<any>;
  listing: any;
  pets?: boolean | null;
  term: number | null | undefined;
  availableSince?: string | null;
}

export interface Message {
  to: {
    firstName: string;
    lastName: string;
    id: string;
    status: CandidateStatus;
  };
  from?: string;
  subject: string;
  id: string;
  status: MessagesStatus;
  body: string;
  created_at: string;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSET = 'UNSET',
}
export enum Employment {
  EMPLOYED_BY_EMPLOYER = 'EMPLOYED_BY_EMPLOYER',
  ENTREPRENEUR = 'ENTREPRENEUR',
  STUDENT = 'STUDENT',
  RETIRED = 'RETIRED',
  UNEMPLOYED = 'UNEMPLOYED',
}
export const Income = {
  ZERO: { placeholder: '€ 0', value: 0 },
  ZERO_FIVE: { placeholder: '€ 0-500', value: 500 },
  FIVEHUNDRED: { placeholder: '€ 501-1000', value: 1000 },
  THOUSAND: { placeholder: '€ 1001-1500', value: 1500 },
  FIFTEENHUNDRED: { placeholder: '€ 1501-2000', value: 2000 },
  TWOTHOUSAND: { placeholder: '€ 2001-2500', value: 2500 },
  TWOTHOUSAND_FIVE: { placeholder: '€ 2501-3000', value: 3000 },
  THREETHOUSAND: { placeholder: '€ 3001-3500', value: 3500 },
  THREEHOUSAND_FIVE: { placeholder: '€ 3501-4000', value: 4000 },
  FOURTHOUSAND: { placeholder: '€ 4001-4500', value: 4500 },
  FIVETHOUSAND_PLUS: { placeholder: '€ 5000 +', value: 5000 },
};
export enum Guarantor {
  IN_NETHERLANDS = 'IN_NETHERLANDS',
  ABROAD = 'ABROAD',
  NO_GUARANTOR = 'NO_GUARANTOR',
}
export const RentalPeriod = {
  INDEFINITE: { placeholder: 'INDEFINITE', value: 0 },
  ZERO_THREE: { placeholder: 'ZERO_THREE', value: 3 },
  THREE_SIX: { placeholder: 'THREE_SIX', value: 6 },
  SIX_TWELVE: { placeholder: 'SIX_TWELVE', value: 12 },
  YEAR: { placeholder: 'YEAR', value: 24 },
};
export enum Household {
  SINGLE = 'SINGLE',
  WITH_PARTNER = 'WITH_PARTNER',
  WITH_FAMILY = 'WITH_FAMILY',
  WITH_FRIENDS = 'WITH_FRIENDS',
}
export interface CandidateFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthday: string | null;
  gender: string;
  employment: string;
  income: number | null;
  guarantor: string;
  householdType: string;
  household: string;
  pets: null | boolean;
  availableSince: string | null;
  term?: number | undefined | null;
  info?: boolean;
  terms?: boolean;
}

export interface CandidateFilterUrlParams {
  'income[gte]': string;
  pets: boolean | null;
  householdType: Array<string>;
  'order[income]'?: SortDirection;
  'order[inquiry_date]'?: SortDirection;
  pagination: boolean;
  status: string;
}

export interface CandidatesState {
  candidate: { data: Candidate; isLoading: boolean };
  candidatesList: { data: Lead; isLoading: boolean };
  editSuccess: boolean;
  enrichingData: any;
  messageSendingSuccess: boolean;
}

export enum CandidateStatusTransitionNames {
  promote = 'promote',
  devote = 'devote',
  reset = 'reset',
  reject = 'reject',
  none = 'none',
}

export interface GetCandidatesDataLoading {
  type: typeof GET_CANDIDATES_DATA_LOADING;
}
export interface GetCandidatesData {
  type: typeof GET_CANDIDATES_DATA;
  payload: Lead;
}
export interface GetCandidatesDataError {
  type: typeof GET_CANDIDATES_DATA_ERROR;
}
export interface SetCandidateStatus {
  type: typeof SET_CANDIDATE_STATUS;
  payload: { ids: Array<string>; status: CandidateStatusTransitionNames };
}
export interface SetCandidateMessage {
  type: typeof SET_CANDIDATE_MESSAGE;
  payload: {
    to: Array<string>;
    message: Message;
  };
}
export interface SetCandidateEditSuccess {
  type: typeof SET_CANDIDATE_EDIT_SUCCESS;
}

export interface GetEnrichingData {
  type: typeof GET_ENRICHING_DATA;
  payload: Lead;
}
export interface SetMessageStatus {
  type: typeof SET_MESSAGE_STATUS;
  payload: { ids: Array<string>; status: MessagesStatus };
}
export interface SetCandidateNote {
  type: typeof SET_CANDIDATE_NOTE;
  payload: { candidateId: string; value: any };
}
export interface RemoveCandidateNote {
  type: typeof REMOVE_CANDIDATE_NOTE;
  payload: { id: string; candidateId: string };
}

export interface GetCandidateProfileDataLoading {
  type: typeof GET_CANDIDATE_PROFILE_DATA_LOADING;
}
export interface GetProfileData {
  type: typeof GET_CANDIDATE_PROFILE_DATA;
  payload: any;
}
export interface GetCandidateProfileDataError {
  type: typeof GET_CANDIDATE_PROFILE_DATA_ERROR;
}

export interface SendMessageSuccess {
  type: typeof SEND_MESSAGE_SUCCESS;
  payload: boolean;
}

export const GET_CANDIDATES_DATA_LOADING = 'GET_CANDIDATES_DATA_LOADING';
export const GET_CANDIDATES_DATA = 'GET_CANDIDATES_DATA';
export const GET_CANDIDATES_DATA_ERROR = 'GET_CANDIDATES_DATA_ERROR';
export const SET_CANDIDATE_STATUS = 'SET_CANDIDATE_STATUS';
export const SET_CANDIDATE_MESSAGE = 'SET_CANDIDATE_MESSAGE';
export const SET_CANDIDATE_EDIT_SUCCESS = 'SET_CANDIDATE_EDIT_SUCCESS';
export const GET_ENRICHING_DATA = 'GET_ENRICHING_DATA';
export const SET_MESSAGE_STATUS = 'SET_MESSAGE_STATUS';
export const SET_CANDIDATE_NOTE = 'SET_CANDIDATE_NOTE';
export const REMOVE_CANDIDATE_NOTE = 'REMOVE_CANDIDATE_NOTE';
export const GET_CANDIDATE_PROFILE_DATA = 'GET_CANDIDATE_PROFILE_DATA';
export const GET_CANDIDATE_PROFILE_DATA_LOADING =
  'GET_CANDIDATE_PROFILE_DATA_LOADING';
export const GET_CANDIDATE_PROFILE_DATA_ERROR =
  'GET_CANDIDATE_PROFILE_DATA_ERROR';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export type CandidatesActions =
  | GetCandidatesDataLoading
  | GetCandidatesData
  | GetCandidatesDataError
  | SetCandidateStatus
  | SetCandidateMessage
  | SetCandidateEditSuccess
  | GetEnrichingData
  | SetMessageStatus
  | SetCandidateNote
  | RemoveCandidateNote
  | GetProfileData
  | GetCandidateProfileDataLoading
  | GetCandidateProfileDataError
  | SendMessageSuccess;
