import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import MaterialContainer from './containers/MaterialContainer';
import { store } from './store/store'

const entryDsn = 
Sentry.init({
  dsn: process.env.NODE_ENV === 'development' ? "https://60ee8da1ac3540eba5f7a645c81fa562@o107911.ingest.sentry.io/6248459" : "https://a1bfaccd7db24bd5887e121d163313dd@o107911.ingest.sentry.io/6248459",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  maxValueLength: 500,
  debug: true,
  environment: process.env.NODE_ENV,
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

declare global {
  interface Window {
    REACT_APP_AUTH_DOMAIN: string | undefined;
    REACT_APP_AUTH_CLIENT_ID: string | undefined;
    REACT_APP_AUTH_AUDIENCE: any | undefined;
    REACT_APP_AUTH_SCOPE: any | undefined;
  }
}

if (process.env.NODE_ENV === 'development') {
  window.REACT_APP_AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
  window.REACT_APP_AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
  window.REACT_APP_AUTH_AUDIENCE = process.env.REACT_APP_AUTH_AUDIENCE;
  window.REACT_APP_AUTH_SCOPE = process.env.REACT_APP_AUTH_SCOPE;
}

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Auth0Provider
        domain={window.REACT_APP_AUTH_DOMAIN as string}
        clientId={window.REACT_APP_AUTH_CLIENT_ID as string}
        redirectUri={window.location.origin}
        audience={window.REACT_APP_AUTH_AUDIENCE}
        scope={window.REACT_APP_AUTH_SCOPE}
        useRefreshTokens>
        <MaterialContainer>
          <Router>
            <App />
          </Router>
        </MaterialContainer>
      </Auth0Provider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);
