import {
    Button,
    Checkbox,
    Grid,
    TextField,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import {  useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../store/store';
import { deleteAgentLogins } from '../../../../../store/thunks/agentsThunk';
import { requestBare } from '../../../../../store/utils/request';
import { useStyles } from './styles';

interface Props {
    uri: string;
    removeAdmin: Function;
  }
  
const AdminLoginsForm:  React.FC<Props>  = ({
    uri, removeAdmin
  }) => {
    const classes = useStyles();
    const [login, setLogin] = useState({login: '', id: 0})
    const dispatch = useDispatch();
    const { token } = useAppSelector((state) => state.user);
    
    const getAdmin = async (uri: string) => {
        const response = await requestBare(token).get(uri)
       setLogin(response.data)
    }

    
    useEffect(() => {
      getAdmin(uri)
    }, [dispatch])
    
    return (
        <div>
            <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            name={`${login.login}`}
                            disabled
                            variant='standard'
                            value={login.login}
                            className={classes.textInput}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.removeEmailContainer}>
                        <Button variant="outlined" onClick={() => removeAdmin(login.id)}>Verwijder email</Button>
                    </Grid>
                </Grid>
        </div >
    )
}

export default AdminLoginsForm
