import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Button, IconButton, Popover } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

const AppHeader: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const { logout, user } = useAuth0();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.root}>
      <AppBar className={classes.toolbar} position="static">
        <Container className={classes.container} maxWidth="lg">
          <nav className={classes.nav}>
            <Typography className={classes.title} variant="h1">
              Kandidatenbeheer
            </Typography>
            <Button
              className={classes.menuButton}
              variant="text"
              onClick={() => history.push('/')}>
              Advertentieoverzicht
            </Button>
          </nav>
          <IconButton
            edge="end"
            className={classes.iconButton}
            color="inherit"
            onClick={handleClick}>
            <PersonIcon />
          </IconButton>
        </Container>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className={classes.popover}>
          <Typography variant="subtitle2">{user?.email}</Typography>
          <Button
            onClick={() => {
              logout({ returnTo: window.location.origin });
              localStorage.removeItem('token');
            }}
            startIcon={<ExitToAppIcon />}>
            Uitloggen
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default AppHeader;
