import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    notesHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginBottom: 20,
    },
    notesTitle: {
      fontSize: 20,
      fontWeight: 700,
    },
    notePaper: {
      padding: '16px 12px',
      marginBottom: 17,
    },
    noteBody: {
      fontSize: 16,
      marginBottom: 12,
    },
    noteTime: {
      color: '#616161',
      fontSize: 12,
      marginRight: 6,
    },
    deleteNoteBtn: {
      fontSize: 12,
      color: '#616161',
    },
    noteBtnContainer: {
      textAlign: 'right',
      '& :last-child': {
        marginLeft: 8,
      },
    },
    newNoteContainer: {
      marginBottom: 30,
      border: '1px solid #000',
      padding: 10,
      borderRadius: 5,
    },
    noteInput: {
      width: '100%',
    },
    noteInputContainer: {
      width: '100%',
      marginBottom: 16,
    },
    error: {
      color: theme.palette.error.main,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: '25px 42px',
      backgroundColor: '#F0F0F0',
      maxWidth: 454,
    },
    title: {
      marginBottom: 12,
    },
    buttonContainer: {
      textAlign: 'right',
      '& > button': {
        fontWeight: 'bold',
        fontSize: 18,
      },
    },
  }),
);
