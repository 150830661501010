import * as Sentry from '@sentry/react';
import axios from 'axios';
import { Dispatch } from 'redux';

import { FilterSortParams, MessagesStatus } from '../../types/entityTypes';
import {
  getCandidateProfileData,
  getCandidateProfileDataLoading,
  getCandidateProfilesDataError,
  getCandidatesData,
  getCandidatesDataError,
  getCandidatesDataLoading,
  getEnrichingData,
  removeCandidateNote,
  sendMessageSuccess,
  setCandidateEditSuccess,
  setCandidateStatusData,
  setMessageData,
  setMessageStatusData,
  setNoteData,
} from '../actions/candidatesDataActions';
import { showSnackbar } from '../actions/uiActions';
import { deleteCandidateNote } from '../api/deleteCandidateNote';
import { fetchCandidateProfile } from '../api/fetchCandidateProfile';
import { fetchCandidates } from '../api/fetchCandidates';
import { fetchEnrichingData } from '../api/fetchEnrichingData';
import { postCandidateData } from '../api/postCandidateData';
import { requestExtraInfo } from '../api/requestExtraInfo';
import { sendCandidateMessage } from '../api/sendCandidateMessage';
import { sendCandidateNote } from '../api/sendCandidateNote';
import { setCandidateData } from '../api/setCandidateData';
import { setCandidateStatus } from '../api/setCandidateStatus';
import { setMessageStatus } from '../api/setMessageStatus';
import {
  CandidateFormValues,
  CandidateStatusTransitionNames,
} from '../types/candidates';
import { SnackbarType } from '../types/ui';

export const getCandidatesThunk =
  (token: string, id: string, filtersValues?: FilterSortParams) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getCandidatesDataLoading());

    let urlParams = {};

    if (filtersValues) {
      const status =
        filtersValues.status?.toLowerCase() === 'all'
          ? null
          : filtersValues.status?.toLowerCase();
      const [sortBy, direction] = filtersValues.sort.split('.');
      urlParams = {
        'income[gte]': filtersValues.income,
        pets: filtersValues.pets,
        householdType: filtersValues.household,
        [`order[${sortBy}]`]: direction,
        page: filtersValues.page + 1,
        itemsPerPage: filtersValues.itemsPerPage,
        pagination: filtersValues.pagination,
        status,
      };
    }
    try {
      const result = await fetchCandidates(token, id, urlParams);
      dispatch(getCandidatesData(result));
    } catch (error: any) {
      Sentry.captureException(error?.response);
      dispatch(getCandidatesDataError());
    }
  };

export const changeCandidateStatusThunk =
  (
    token: string,
    {
      ids,
      status,
    }: {
      ids: Array<string>;
      status: CandidateStatusTransitionNames;
    },
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await setCandidateStatus(token, ids, status);
      dispatch(setCandidateStatusData({ ids, status }));
      if (status === CandidateStatusTransitionNames.reject) {
        dispatch(
          showSnackbar(
            'Kandidaten gemarkeerd als afgewezen, e-mail met afwijzing verzonden',
            SnackbarType.success,
          ),
        );
      } else {
        dispatch(showSnackbar('Status aangepast', SnackbarType.success));
      }
    } catch (error: any) {
      Sentry.captureException(error.response);
      dispatch(showSnackbar('Statusinstelling mislukt', SnackbarType.error));
    }
  };

export const sendMessageThunk =
  (
    token: string,
    {
      to,
      message,
    }: {
      to: Array<string>;
      message: { subject: string; body: string };
    },
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await sendCandidateMessage(token, message, to);
      dispatch(sendMessageSuccess(true));
      dispatch(
        showSnackbar('Bericht succesvol verstuurd', SnackbarType.success),
      );
    } catch (error: any) {
      Sentry.captureException(error.response);
      if (axios.isAxiosError(error)) {
        dispatch(showSnackbar('Bericht verzenden mislukt', SnackbarType.error));
        console.log(error.response);
        dispatch(sendMessageSuccess(false));
      }
    }
  };

export const sendCandidateDataThunk =
  (
    token: string,
    { id, values }: { id: string; values: CandidateFormValues },
    handleRedirect?: () => void,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await setCandidateData(token, id, values);
      dispatch(setCandidateEditSuccess());
      handleRedirect && handleRedirect();
      dispatch(
        showSnackbar('Gegevens kandidaat opgeslagen', SnackbarType.success),
      );
    } catch (error: any) {
      Sentry.captureException(error.response);
      dispatch(
        showSnackbar('Kandidaatgegevens opslaan mislukt', SnackbarType.error),
      );
    }
  };

export const getEnrichingDataThunk =
  (token: string, uid: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getCandidatesDataLoading());
    try {
      const result = await fetchEnrichingData(token, uid);
      dispatch(getEnrichingData(result));
    } catch (error: any) {
      Sentry.captureException(error.response);
      dispatch(getCandidatesDataError());
    }
  };

export const requestExtraInfoThunk =
  (
    token: string,
    {
      agentEmail,
      candidatesIds,
    }: {
      agentEmail: string;
      candidatesIds: Array<string>;
    },
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await requestExtraInfo(token, { agentEmail, candidatesIds });
      dispatch(
        showSnackbar('Bericht succesvol verzonden', SnackbarType.success),
      );
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        dispatch(showSnackbar('Verzoek mislukt', SnackbarType.error));
        console.log(error.response);
      }
    }
  };

export const changeMessageStatusThunk =
  (
    token: string,
    { ids, status }: { ids: Array<string>; status: MessagesStatus },
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await setMessageStatus(token, ids, status);
      dispatch(setMessageStatusData({ ids, status }));
    } catch (error: any) {
      Sentry.captureException(error.response);
      console.log(error);
    }
  };

export const createNoteThunk =
  (
    token: string,
    { candidateId, value }: { candidateId: string; value: string },
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const result = await sendCandidateNote(token, candidateId, value);
      dispatch(setNoteData({ candidateId, value: result }));
      dispatch(
        showSnackbar('Notitie succesvol opgeslagen', SnackbarType.success),
      );
    } catch (error: any) {
      Sentry.captureException(error.response);
      if (axios.isAxiosError(error)) {
        dispatch(showSnackbar('Notitie verzenden mislukt', SnackbarType.error));
        console.log(error.response);
      }
    }
  };

export const deleteNoteThunk =
  (token: string, { id, candidateId }: { id: string; candidateId: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await deleteCandidateNote(token, id);
      dispatch(removeCandidateNote({ id, candidateId }));
      dispatch(
        showSnackbar('Opmerking succesvol verwijderd', SnackbarType.success),
      );
    } catch (error: any) {
      Sentry.captureException(error.response);
      if (axios.isAxiosError(error)) {
        dispatch(
          showSnackbar('Verwijderen van notitie mislukt', SnackbarType.error),
        );
      }
    }
  };

export const createCandidateThunk =
  (
    token: string,
    { id, values }: { id: string; values: CandidateFormValues },
    handleRedirect: () => void,
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await postCandidateData(token, id, values);
      dispatch(
        showSnackbar('Gegevens kandidaat opgeslagen', SnackbarType.success),
      );
      await handleRedirect();
    } catch (error: any) {
      Sentry.captureException(error.response);
      dispatch(showSnackbar('Kandidaat opslaan mislukt', SnackbarType.error));
    }
  };

export const getCandidateProfileThunk =
  (token: string, candidateId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getCandidateProfileDataLoading());
    try {
      const result = await fetchCandidateProfile(token, candidateId);
      dispatch(getCandidateProfileData(result));
    } catch (error: any) {
      Sentry.captureException(error.response);
      dispatch(getCandidateProfilesDataError());
    }
  };
