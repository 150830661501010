import { Tab, Tabs, Typography } from '@material-ui/core';
import AllInboxOutlinedIcon from '@material-ui/icons/AllInboxOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import React from 'react';

import { MessageFilterTabsState } from '../../../../types/entityTypes';
import { useStyles } from './styles';

type Props = {
  state: MessageFilterTabsState;
  handleChange: (
    event: React.ChangeEvent<{}>,
    newValue: MessageFilterTabsState,
  ) => void;
  allCount: number;
  unreadCount: number;
  readCount: number;
};

export const FilterMessagesTabs: React.FC<Props> = ({
  state,
  handleChange,
  allCount,
  unreadCount,
  readCount,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Tabs value={state} onChange={handleChange}>
        <Tab
          value={MessageFilterTabsState.UNREAD}
          label={
            <div className={classes.tabLabel}>
              <EmailOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Ongelezen ({unreadCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={MessageFilterTabsState.READ}
          label={
            <div className={classes.tabLabel}>
              <DraftsOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Gelezen ({readCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={MessageFilterTabsState.ALL}
          label={
            <div className={classes.tabLabel}>
              <AllInboxOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Alle ({allCount})
              </Typography>
            </div>
          }
        />
      </Tabs>
    </div>
  );
};
