import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    messagesTitle: {
      fontSize: 20,
      fontWeight: 700,
      marginBottom: 20,
    },
    createMessageBlock: {
      paddingBottom: 8,
      borderBottom: '1px solid #D1D1D1',
      marginBottom: 14,
    },
    fromTo: {
      color: '#555555',
    },
    messageInput: {
      width: '100%',
      marginBottom: 8,
    },
    sendBtn: {
      display: 'block',
      marginLeft: 'auto',
    },
    mailTitle: {
      fontWeight: 'bold',
      fontSize: 16,
    },
    mailCaption: {
      marginBottom: 16,
    },
    mailContainer: {
      paddingBottom: 20,
      borderBottom: '1px solid #D1D1D1',
      marginBottom: 14,
    },
    replyBtnContainer: {
      textAlign: 'right',
    },
    replyBtn: {
      backgroundColor: '#777777',
      color: '#FFF',
      fontWeight: 'bold',
    },
  }),
);
