import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import {
  getAdsData,
  getAdsDataError,
  getAdsDataLoading,
} from '../actions/adsActions';
import { showSnackbar } from '../actions/uiActions';
import { fetchAds } from '../api/fetchAds';
import { toggleAutoEnriching } from '../api/toggleAutomaticEnriching';
import { AdsFormValues } from '../types/ads';
import { SnackbarType } from '../types/ui';

type ServerError = { errorMessage: string };

export const getAdsThunk =
  (token: string, { values }: { values: AdsFormValues }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getAdsDataLoading());

    try {
      const result = await fetchAds(token, values);
      dispatch(getAdsData(result));
      dispatch(getAdsDataError({ error: false, errorMessage: ``, code: 200 }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAdsDataError({
            error: true,
            errorMessage: error.response?.data.message,
            code: error.response?.data.status,
          }),
        );
      }
    }
  };

export const editAutomaticEnriching =
  (token: string, enrichmentMode: string, listingIds: Array<string>) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const result = await toggleAutoEnriching(
        token,
        enrichmentMode,
        listingIds,
      );
      dispatch(
        showSnackbar('Automatisch Informatie opvragen', SnackbarType.success),
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          showSnackbar(
            'Automatisch Informatie opvragen mislukt',
            SnackbarType.error,
          ),
        );
      }
    }
  };
