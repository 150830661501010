import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '18px 30px',
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 24,
    },
    headerContainer: {
      marginBottom: 12,
    },
    headerTitlesContainer: {
      marginRight: 16,
    },
    bodyInput: {
      width: '100%',
      marginBottom: 20,
    },
    buttonContainer: {
      textAlign: 'right',
      '& > button': {
        marginRight: 12,
      },
      '& :nth-child(2)': {
        marginRight: 0,
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: '25px 42px',
      backgroundColor: '#F0F0F0',
      maxWidth: 454,
    },
    modalTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 24,
    },
    subjectInput: {
      width: '100%',
    },
    sebjectTitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    containerWithBotMargin: {
      marginBottom: 18,
    },
    descriptionTitle: {
      marginRight: 12,
    },
    submitBtn: {
      backgroundColor: '#777777',
      color: '#fff',
      fontWeight: 'bold',
    },
  }),
);
