import { CandidateStatusTransitionNames } from '../types/candidates';
import { request } from '../utils/request';

export const setCandidateStatus = async (
  token: string,
  ids: Array<string>,
  status: CandidateStatusTransitionNames,
): Promise<any> => {
  await request(token).post(`/bulk/candidates/status/${status}`, {
    candidatesIds: ids,
  });
};
