import { Reducer } from 'redux';

import {
    REMOVE_TOKEN,
    SET_TOKEN,
    UserState
} from '../types/user';

const initialState: UserState = {
  token: ''
};

const userReducer: Reducer<UserState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REMOVE_TOKEN: {
      return {
        ...state,
        token: null,
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload.token,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
