import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filterBtnContainer: {
      marginTop: 28,
    },
    candidateCount: {
      fontSize: 18,
      fontWeight: 'bold',
      marginTop: 24,
    },
  }),
);
