import { Slide, Snackbar as MuiSnackBar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { useDispatch } from 'react-redux';

import { closeSnackbar } from '../../../store/actions/uiActions';
import { useAppSelector } from '../../../store/store';

const Snackbar: React.FC = () => {
  const dispatch = useDispatch();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  const {
    snackbar: { visible, type, message },
  } = useAppSelector((state) => state.ui);

  return (
    <MuiSnackBar
      TransitionComponent={Slide}
      open={visible}
      autoHideDuration={3000}
      onClose={handleClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={type}>
        {message}
      </MuiAlert>
    </MuiSnackBar>
  );
};

export default Snackbar;
