import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      border: '1px solid #000',
      padding: 8,
      margin: 4,
    },
    selected: {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  }),
);
