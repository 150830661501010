import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import adsReducer from './reducers/adsReducer';
import agentsReducer from './reducers/agentsReducer';
import candidatesReducer from './reducers/candidatesReducer';
import uiReducer from './reducers/uiReducer';
import userReducer from './reducers/userReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const store = createStore(
  combineReducers({
    candidates: candidatesReducer,
    ads: adsReducer,
    ui: uiReducer,
    user: userReducer,
    agents: agentsReducer
  }),
  composeEnhancers(applyMiddleware(thunk)),
);
