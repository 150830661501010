import { useAuth0 } from '@auth0/auth0-react';
import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../store/store';
import { getCandidatesThunk } from '../../../store/thunks/candidatesThunk';
import {
  MessageFilterTabsState,
  MessagesStatus,
} from '../../../types/entityTypes';
import MessagesTable from '../MessagesTable/MessagesTable';
import { FilterMessagesTabs } from './FilterMessagesTabs/FilterMessagesTabs';
import { useStyles } from './styles';

export const MessagesTab: React.FC = () => {
  const classes = useStyles();
  const { isLoading, data } = useAppSelector(
    (state) => state.candidates.candidatesList,
  );

  const dispatch = useDispatch();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { id } = useParams() as { id: string };
  const location = useLocation() as { state?: { status: MessagesStatus } };

  const filterTabDefault = useMemo(() => {
    if (location.state?.status === MessagesStatus.unread) {
      return MessageFilterTabsState.UNREAD;
    }
    return MessageFilterTabsState.ALL;
  }, [location.state?.status]);

  const [filterTab, setFilterTab] =
    useState<MessageFilterTabsState>(filterTabDefault);

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(getCandidatesThunk(accessToken, id));
      }
      catch (e: any) {
        console.log(e.message);
      }
    };
  
    getUserToken();
   
  }, [dispatch, id]);

  const handleFilterTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: MessageFilterTabsState,
  ) => {
    setFilterTab(newValue);
  };

  const { unreadMessages, readMessages } = useMemo(
    () => ({
      unreadMessages: data.messages.filter(
        (el) => el.status === MessagesStatus.unread,
      ),
      readMessages: data.messages.filter(
        (el) => el.status === MessagesStatus.read,
      ),
    }),
    [data.messages],
  );

  const messagesTableData = useMemo(() => {
    switch (filterTab) {
      case MessageFilterTabsState.UNREAD:
        return unreadMessages;
      case MessageFilterTabsState.READ:
        return readMessages;
      case MessageFilterTabsState.ALL:
        return data.messages;

      default:
        return [];
    }
  }, [filterTab, data.messages, readMessages, unreadMessages]);

  return (
    <>
      <Grid container spacing={6} className={classes.header}>
        <Grid item xl={6}>
          <FilterMessagesTabs
            readCount={readMessages.length}
            unreadCount={unreadMessages.length}
            allCount={data.messages.length}
            state={filterTab}
            handleChange={handleFilterTabChange}
          />
        </Grid>
      </Grid>
      <MessagesTable
        candidatesArray={data.candidates}
        isLoading={isLoading}
        messages={messagesTableData}
      />
    </>
  );
};
