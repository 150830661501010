import {
  Button,
  Checkbox,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Filters } from '../../../../../types/entityTypes';
import { NumberFormatCustom } from './NumberFormatCustomProps';
import { useStyles } from './styles';

type Props = {
  anchorEl: HTMLButtonElement | null;
  handleClose: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  values: Filters;
  applyFilters: (values: Filters) => void;
};

export const FiltersPopover: React.FC<Props> = ({
  anchorEl,
  handleClose,
  values,
  applyFilters,
}) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const [filterValues, setFilterValues] = useState({ ...values });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValues({
      ...filterValues,
      [event.target.name]: event.target.value,
    });
  };
  const removeInput =(i:string) => {
    const index = filterValues.household.indexOf(i);
    if (index > -1) {
      filterValues.household.splice(index, 1);
    }
    setFilterValues({
      ...filterValues,
      household:  filterValues.household
    });
  }
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 if(filterValues.household.includes(event.target.name.toUpperCase())){
  removeInput(event.target.name.toUpperCase())
 }else{
  setFilterValues({
    ...filterValues,
    household: [
      ...filterValues.household,
      event.target.name.toUpperCase()
    ],
  });
 }
   
  };

 
  
  const clearForm = () => {
    const defaultValues = {
      income: '',
      pets: null,
      household: [],
      page: 0,
      itemsPerPage: 30,
      pagination: false
    };
    setFilterValues(defaultValues);
    applyFilters(defaultValues);
    handleClose(null);
  };

  const handleSubmit = () => {
    applyFilters(filterValues);
    handleClose(null);
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 35,
      }}>
      <div className={classes.root}>
        <div className={classes.form}>
          <div className={classes.formColumn}>
            <Typography variant="subtitle1" className={classes.columnHeader}>
              Minimale inkomen
            </Typography>
            <TextField
              variant="outlined"
              value={filterValues.income}
              onChange={handleChange}
              name="income"
              InputProps={{
                inputComponent: NumberFormatCustom as any,
              }}
            />
          </div>
          <div className={classes.formColumn}>
            <Typography variant="subtitle1" className={classes.columnHeader}>
              Huisdieren
            </Typography>
            <RadioGroup
              name="pets"
              value={filterValues.pets}
              onChange={handleChange}>
              <FormControlLabel
                value="1"
                control={<Radio checked={filterValues.pets === '1'} />}
                label="Ja"
              />
              <FormControlLabel
                value="0"
                control={<Radio checked={filterValues.pets === '0'} />}
                label="Nee"
              />
            </RadioGroup>
          </div>
          <div className={classes.formColumn}>
            <Typography variant="subtitle1" className={classes.columnHeader}>
              Huishouden
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterValues.household.includes('SINGLE')}
                  onChange={handleCheckboxChange}
                  name="single"
                />
              }
              label="Alleenstaand"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterValues.household.includes('WITH_PARTNER')}
                  onChange={handleCheckboxChange}
                  name="with_partner"
                />
              }
              label="Samenwonend"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterValues.household.includes('WITH_FAMILY')}
                  onChange={handleCheckboxChange}
                  name="with_family"
                />
              }
              label="Gezin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterValues.household.includes('WITH_FRIENDS')}
                  onChange={handleCheckboxChange}
                  name="with_friends"
                />
              }
              label="Delers"
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            onClick={clearForm}
            className={classes.refreshBtn}>
            Filters ongedaan maken
          </Button>
          <Button variant="text" onClick={() => handleClose(null)}>
            Sluiten
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmit()}>
            Toepassen
          </Button>
        </div>
      </div>
    </Popover>
  );
};
