import { Locale } from '../../config/I18n';
import { HeaderTab } from '../../types/entityTypes';
import {
  CLOSE_SNACKBAR,
  SET_HEADER_TAB,
  SET_LOCALE,
  SHOW_SNACKBAR,
  SnackbarType,
  TOGGLE_PROFILE_EDIT,
  UIActions,
} from '../types/ui';

export const showSnackbar = (
  message: string,
  type: SnackbarType,
): UIActions => {
  return {
    type: SHOW_SNACKBAR,
    payload: { message, type },
  };
};

export const closeSnackbar = (): UIActions => {
  return {
    type: CLOSE_SNACKBAR,
  };
};

export const setLocale = (payload: Locale): UIActions => {
  return {
    type: SET_LOCALE,
    payload,
  };
};
export const setHeaderTab = (payload: HeaderTab): UIActions => {
  return {
    type: SET_HEADER_TAB,
    payload,
  };
};
export const toggleProfileEdit = (payload: boolean): UIActions => {
  return {
    type: TOGGLE_PROFILE_EDIT,
    payload,
  };
};
