export const formatDate = (
  value: string | Date,
  options: Intl.DateTimeFormatOptions,
): string => {
  if (!value) return '';
  let date;
  if (typeof value === 'string') {
    const formatedStr = value.replace(/-/g, '/');
    date = new Date(formatedStr);
  } else {
    date = new Date(value);
  }
  return new Intl.DateTimeFormat('nl-NL', options).format(date);
};
