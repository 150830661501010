import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        marginBottom: 8,
      },
    },
    subtitle: {
      fontSize: 16,
      fontWeight: 600,
      margin: '37px 0 10px',
    },
    submitBtn: {
      alignSelf: 'flex-start',
      backgroundColor: '#777777',
      color: '#fff',
      fontWeight: 'bold',
      margin: '47px 0 36px',
    },
    rentalPeriodContainer: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '67px !important',
    },
    startDate: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    rentalPeriod: {
      width: '50%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    disclamer: {
      display: 'flex',
      alignItems: 'center',
      color: '#777777',
      fontSize: 12,
    },
    error: {
      color: theme.palette.error.main,
    },
    buttonContainer: {
      textAlign: 'right',
      '& > button': {
        marginRight: 12,
      },
      '& :nth-child(2)': {
        marginRight: 0,
      },
    },
    saveBtn: {
      backgroundColor: '#777777',
      color: '#fff',
      fontWeight: 'bold',
      marginRight: 0,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: '25px 42px',
      backgroundColor: '#F0F0F0',
      maxWidth: 454,
    },
    modalTitle: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 24,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 24,
    },
  }),
);
