import { Reducer } from 'redux';

import { Locale } from '../../config/I18n';
import { HeaderTab } from '../../types/entityTypes';
import {
  CLOSE_SNACKBAR,
  SET_HEADER_TAB,
  SET_LOCALE,
  SHOW_SNACKBAR,
  SnackbarType,
  TOGGLE_PROFILE_EDIT,
  UIActions,
  UIState,
} from '../types/ui';

const initialState: UIState = {
  snackbar: {
    visible: false,
    type: SnackbarType.success,
    message: '',
  },
  locale: Locale.NL,
  headerTab: HeaderTab.CANDIDATE,
  editCandidateOpen: false,
};

const uiReducer: Reducer<UIState, UIActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      return {
        ...state,
        snackbar: { visible: true, ...action.payload },
      };
    }
    case CLOSE_SNACKBAR: {
      return {
        ...state,
        snackbar: { ...state.snackbar, visible: false },
      };
    }
    case SET_LOCALE: {
      localStorage.setItem('locale', action.payload);
      return {
        ...state,
        locale: action.payload,
      };
    }
    case SET_HEADER_TAB: {
      return {
        ...state,
        headerTab: action.payload,
      };
    }
    case TOGGLE_PROFILE_EDIT: {
      return {
        ...state,
        editCandidateOpen: action.payload,
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
