import { boolean } from 'yup/lib/locale';

import { MessagesStatus } from '../../types/entityTypes';
import {
  CandidatesActions,
  CandidateStatusTransitionNames,
  GET_CANDIDATE_PROFILE_DATA,
  GET_CANDIDATE_PROFILE_DATA_ERROR,
  GET_CANDIDATE_PROFILE_DATA_LOADING,
  GET_CANDIDATES_DATA,
  GET_CANDIDATES_DATA_ERROR,
  GET_CANDIDATES_DATA_LOADING,
  GET_ENRICHING_DATA,
  Message,
  REMOVE_CANDIDATE_NOTE,
  SEND_MESSAGE_SUCCESS,
  SET_CANDIDATE_EDIT_SUCCESS,
  SET_CANDIDATE_MESSAGE,
  SET_CANDIDATE_NOTE,
  SET_CANDIDATE_STATUS,
  SET_MESSAGE_STATUS,
} from '../types/candidates';

export const getCandidatesDataLoading = (): CandidatesActions => {
  return {
    type: GET_CANDIDATES_DATA_LOADING,
  };
};

export const getCandidatesData = (payload: any): CandidatesActions => {
  return {
    type: GET_CANDIDATES_DATA,
    payload,
  };
};

export const getCandidatesDataError = (): CandidatesActions => {
  return {
    type: GET_CANDIDATES_DATA_ERROR,
  };
};

export const setCandidateStatusData = (payload: {
  ids: Array<string>;
  status: CandidateStatusTransitionNames;
}): CandidatesActions => {
  return {
    type: SET_CANDIDATE_STATUS,
    payload,
  };
};

export const setMessageData = (payload: {
  to: Array<string>;
  message: Message;
}): CandidatesActions => {
  return {
    type: SET_CANDIDATE_MESSAGE,
    payload,
  };
};

export const setCandidateEditSuccess = (): CandidatesActions => {
  return {
    type: SET_CANDIDATE_EDIT_SUCCESS,
  };
};

export const getEnrichingData = (payload: any): CandidatesActions => {
  return {
    type: GET_ENRICHING_DATA,
    payload,
  };
};

export const setMessageStatusData = (payload: {
  ids: Array<string>;
  status: MessagesStatus;
}): CandidatesActions => {
  return {
    type: SET_MESSAGE_STATUS,
    payload,
  };
};

export const setNoteData = (payload: {
  candidateId: string;
  value: any;
}): CandidatesActions => {
  return {
    type: SET_CANDIDATE_NOTE,
    payload,
  };
};

export const removeCandidateNote = (payload: {
  id: string;
  candidateId: string;
}): CandidatesActions => {
  return {
    type: REMOVE_CANDIDATE_NOTE,
    payload,
  };
};

export const getCandidateProfileDataLoading = (): CandidatesActions => {
  return {
    type: GET_CANDIDATE_PROFILE_DATA_LOADING,
  };
};
export const getCandidateProfileData = (payload: any): CandidatesActions => {
  return {
    type: GET_CANDIDATE_PROFILE_DATA,
    payload,
  };
};
export const getCandidateProfilesDataError = (): CandidatesActions => {
  return {
    type: GET_CANDIDATE_PROFILE_DATA_ERROR,
  };
};

export const sendMessageSuccess = (payload: boolean): CandidatesActions => {
  return {
    type: SEND_MESSAGE_SUCCESS,
    payload,
  };
};
