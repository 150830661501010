import axios, { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import {
  getAgentLogins,
  getAgentsData,
  getAgentsDataError,
  getAgentsDataLoading,
  getAgentsDetailsData,
  setAgentLoginsFromApi
} from '../actions/agentActions';
import { showSnackbar } from '../actions/uiActions';
import { createAgentConfiguration, getAgentLoginEmails, removeAgentLoginEmails, saveAgentLoginEmails, updateAgentConfiguration } from '../api/createAgent';
import { fetchAgentDetails, fetchAgents } from '../api/fetchAgents';
import { ShowSnackbar, SnackbarType } from '../types/ui';

type ServerError = { errorMessage: string };

export const getAgentsThunk =
  (token: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(getAgentsDataLoading());
      try {
        const result = await fetchAgents(token);
        dispatch(getAgentsData(result));
        dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            getAgentsDataError({
              error: true,
              errorMessage: error.response?.data.message,
              code: error.response?.data.status,
            }),

          );

        }
      }
    };


export const getAgentsConfiguration =
  (token: string, id: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(getAgentsDataLoading());
      try {
        const result = await fetchAgentDetails(token, id);
        console.log(result)
        dispatch(getAgentsDetailsData(result.data));
        dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            getAgentsDataError({
              error: true,
              errorMessage: error.response?.data.message,
              code: error.response?.data.status,
            }),
          );
        }
      }
    };


export const createAgent =
  (token: string, data: any) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(getAgentsDataLoading());
      try {
        const result = await createAgentConfiguration(token, data);
        dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
        dispatch(showSnackbar('Makelaar succesvol aangemaakt', SnackbarType.success));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            getAgentsDataError({
              error: true,
              errorMessage: error.response?.data.message,
              code: error.response?.data.status,
            }),
          );
          dispatch(showSnackbar('Makelaar aanmaken mislukt', SnackbarType.error));
        }
      }
    };

export const updateAgent =
  (token: string, id: string, data: any) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(getAgentsDataLoading());
      try {
        const result = await updateAgentConfiguration(token, id, data);
        dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
        dispatch(showSnackbar('Makelaar succesvol bewerkt', SnackbarType.success));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          dispatch(
            getAgentsDataError({
              error: true,
              errorMessage: error.response?.data.message,
              code: error.response?.data.status,
            }),
          );
          dispatch(showSnackbar('Makelaar bewerken mislukt', SnackbarType.error));
        }
      }
    };

export const saveAgentLogins = (token: string, id: string, email: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getAgentsDataLoading());
    try {
      const result = await saveAgentLoginEmails(token, id, email);
      console.log("saveAgentLoginsResponse", result.data)
      dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAgentsDataError({
            error: true,
            errorMessage: error.response?.data.message,
            code: error.response?.data.status,
          }),
        );
        dispatch(showSnackbar('Er is iets misgegaan', SnackbarType.error));
      }
    }
  }

export const fetchAgentLogins = (token: string, id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getAgentsDataLoading());
    try {
      const result = await getAgentLoginEmails(token, id);
      console.log(result)
      dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAgentsDataError({
            error: true,
            errorMessage: error.response?.data.message,
            code: error.response?.data.status,
          }),
        );
        dispatch(showSnackbar('Er is iets misgegaan', SnackbarType.error));
      }
    }
  }

export const deleteAgentLogins = (token: string, id: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(getAgentsDataLoading());
    try {
      const result = await removeAgentLoginEmails(token, id);
      console.log(result)
      dispatch(getAgentsDataError({ error: false, errorMessage: ``, code: 200 }));
      dispatch({ type: 'REMOVE_LOGIN', payload: id })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        dispatch(
          getAgentsDataError({
            error: true,
            errorMessage: error.response?.data.message,
            code: error.response?.data.status,
          }),
        );
        dispatch(showSnackbar('Er is iets misgegaan', SnackbarType.error));
      }
    }
  }
