import { AxiosResponse } from 'axios';

import { Lead } from '../types/candidates';
import { request } from '../utils/request';

export const fetchEnrichingData = async (
  token: string,
  id: string,
): Promise<AxiosResponse<Lead>> => {
  const { data } = await request(token).get(`/candidates/${id}/listing`);
  return data;
};
