import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setHeaderTab } from '../../../store/actions/uiActions';
import { setUserToken } from '../../../store/actions/userActions';
import { useAppSelector } from '../../../store/store';
import { getAdsThunk } from '../../../store/thunks/adsThunk';
import {
  CandidateStatus,
  HeaderTab,
  MessagesStatus,
} from '../../../types/entityTypes';
import { useStyles } from './styles';

const AdOverview: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();
  const {
    data: listings,
    isLoading,
    error,
    errorMessage,
    code,
  } = useAppSelector((state) => state.ads);

  const { token } = useAppSelector((state) => state.user);

  const onChangeSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRedirect = (
    id: string,
    headerTab: HeaderTab,
    param?: CandidateStatus | MessagesStatus,
  ) => {
    if (param) {
      history.push({ pathname: `/dashboard/${id}`, state: { status: param } });
    } else {
      history.push(`/dashboard/${id}`);
    }
    dispatch(setHeaderTab(headerTab));
  };

 const renderDate = (d:number) => {
  if(d > 7){
   return `${Math.round(d / 7)}w`
  }
  return `${d}d`
 }

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(setUserToken({ token: accessToken }));
        dispatch(
          getAdsThunk(accessToken, {
            values: {
              pagination: true,
              page: page + 1,
              itemsPerPage: rowsPerPage,
              listing: searchText.length > 1 ? searchText : null,
            },
          }),
        );
      } catch (e: any) {
        console.log(e.message);
      }
    };

    getUserToken();
  }, [dispatch, page, rowsPerPage, searchText]);

  return (
    <div>
      <div>
        <TableContainer style={{ height: 'auto' }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography className={classes.tableTitle} variant="h4">
                Advertentieoverzicht
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                value={searchText}
                onChange={(e) => onChangeSearch(e)}
                id="outlined-size-small"
                size="medium"
                variant="outlined"
                placeholder="Zoeken"
                fullWidth
                style={{
                  marginTop: 0,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Table>
            <TableHead>
              <TableRow className={classes.tableHead}>
                <TableCell component="th">
                  <Typography className={classes.theadTitle} variant="body1">
                    Advertentie
                  </Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography className={classes.theadTitle} variant="body1">
                    Beschikbaar in
                  </Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography className={classes.theadTitle} variant="body1">
                    Online sinds
                  </Typography>
                </TableCell>
                <TableCell component="th">
                  <Typography className={classes.theadTitle} variant="body1">
                    Kandidaten
                  </Typography>
                  <div className={classes.subCell}>
                    <Typography variant="subtitle2">Nieuw</Typography>
                    <Typography variant="subtitle2">Te beoordelen</Typography>
                    <Typography variant="subtitle2" style={{marginLeft: '20px'}}>Geschikt</Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <>
                  <CircularProgress />
                </>
              ) : (
                <>
                  {listings['hydra:totalItems'] === 0 ? (
                    <>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body1">
                            Geen advertenties om te weergeven
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      {listings['hydra:member'].map((ad) => {
                        return (
                          <TableRow hover key={ad.id}>
                            <TableCell>
                              <Typography
                               
                                variant="h5"
                                onClick={() =>
                                  handleRedirect(ad.id, HeaderTab.CANDIDATE)
                                }>
                               <span  className={classes.addressTitle}>{ad.street} {ad.houseNumber}, {ad.city}</span> 
                              </Typography>
                              <Typography variant="caption">
                                &euro;{ad.price} - {ad.surface} m2 - {ad.rooms}{' '}
                                kamers
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{renderDate(ad.availabilityInDays)}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body1">{renderDate(ad.onlinePeriodInDays)}</Typography>
                            </TableCell>
                            <TableCell>
                              <div className={classes.subCell}>
                                <Typography
                                  onClick={() =>
                                    handleRedirect(
                                      ad.id,
                                      HeaderTab.CANDIDATE,
                                      CandidateStatus.none,
                                    )
                                  }
                                  variant="body1"
                                  className={`${classes.tableClickable} ${
                                    ad.numberOfUnassessedLeads > 0
                                      ? classes.bold
                                      : undefined
                                  }`}>
                                  {ad.numberOfUnassessedLeads}
                                </Typography>
                                <Typography
                                  onClick={() =>
                                    handleRedirect(
                                      ad.id,
                                      HeaderTab.CANDIDATE,
                                      CandidateStatus.assessment,
                                    )
                                  }
                                  variant="body1"
                                  className={`${classes.tableClickable} ${
                                    ad.numberOfLeadsToAssess > 0
                                      ? classes.bold
                                      : undefined
                                  }`}>
                                  {ad.numberOfLeadsToAssess}
                                </Typography>
                                <Typography
                                  onClick={() =>
                                    handleRedirect(
                                      ad.id,
                                      HeaderTab.CANDIDATE,
                                      CandidateStatus.suitable,
                                    )
                                  }
                                  variant="body1"
                                  className={`${classes.tableClickable} ${
                                    ad.numberOfSuitableLeads > 0
                                      ? classes.bold
                                      : undefined
                                  }`}>
                                  {ad.numberOfSuitableLeads}
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        style={{ position: 'sticky', bottom: 0, background: '#fafafa' }}
        rowsPerPageOptions={[5, 10, 30]}
        component="div"
        count={listings['hydra:totalItems']}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default AdOverview;
