import { request } from '../utils/request';

export const sendCandidateMessage = async (
  token: string,
  message: { subject: string; body: string },
  to: Array<string>,
): Promise<any> => {
  await request(token).post(`mail/send`, {
    personalizations: [
      {
        to: [
          {
            candidateIds: to,
          },
        ],
      },
    ],
    subject: message.subject,
    content: [
      {
        type: 'text/plain',
        value: message,
      },
    ],
  });
};
