import { stat } from 'fs';
import { Reducer } from 'redux';

import { CandidateStatus } from '../../types/entityTypes';
import {
  CandidatesActions,
  CandidatesState,
  CandidateStatusTransitionNames,
  GET_CANDIDATE_PROFILE_DATA,
  GET_CANDIDATE_PROFILE_DATA_ERROR,
  GET_CANDIDATE_PROFILE_DATA_LOADING,
  GET_CANDIDATES_DATA,
  GET_CANDIDATES_DATA_ERROR,
  GET_CANDIDATES_DATA_LOADING,
  GET_ENRICHING_DATA,
  REMOVE_CANDIDATE_NOTE,
  SEND_MESSAGE_SUCCESS,
  SET_CANDIDATE_EDIT_SUCCESS,
  SET_CANDIDATE_MESSAGE,
  SET_CANDIDATE_NOTE,
  SET_CANDIDATE_STATUS,
  SET_MESSAGE_STATUS,
} from '../types/candidates';

const initialState: CandidatesState = {
  candidate: {
    data: {
      email: '',
      firstName: '',
      household: '',
      householdType: '',
      id: '',
      income: 0,
      lastName: '',
      listing: {},
      guarantor: '',
      locale: '',
      phone: '',
      messages: [],
      status: CandidateStatus.none,
      notes: [],
      pets: null,
      term: null,
      availableSince: null,
    },
    isLoading: false,
  },
  candidatesList: {
    data: {
      status: '',
      settings: {
        '@id': '',
        '@type': '',
        details: {
          enrichment: '',
          city: '',
          link: '',
          price: 0,
          rooms: 0,
          surface: 0,
          imageUrl: '',
          listingId: '',
          postalCode: '',
          description: '',
          houseNuber: '',
          originName: '',
          originId: '',
          agentDetails: {
            url: '',
            logo: '',
            privacyPolicy: '',
            name: '',
          },
          showHouseNumber: false,
        },
        city: '',
        description: '',
        enrichment: '',
        houseNumber: '',
        imageUrl: null,
        link: '',
        listingId: '',
        originId: null,
        originName: '',
        postalCode: '',
        price: 0,
        rooms: 0,
        showHouseNumber: true,
        street: '',
        streetWithHouseNumber: '',
        surface: 0,
      },
      details: {
        enrichment: '',
        city: '',
        link: '',
        price: 0,
        rooms: 0,
        surface: 0,
        imageUrl: '',
        listingId: '',
        postalCode: '',
        description: '',
        houseNuber: '',
        originName: '',
        originId: '',
        agentDetails: {
          url: '',
          logo: '',
          privacyPolicy: '',
          name: '',
        },
        showHouseNumber: false,
      },
      totalAmountCandidates: 0,
      candidates: [],
      city: '',
      houseNumber: '',
      id: '',
      numberOfLeads: 0,
      street: '',
      description: '',
      imageUrl: '',
      link: '',
      originId: '',
      originName: '',
      postalCode: '',
      price: 0,
      rooms: 0,
      surface: 0,
      messages: [],
      numberOfLeadsToAssess: 0,
      numberOfUnassessedLeads: 0,
      numberOfRejectedLeads: 0,
      numberOfSuitableLeads: 0,
      numberOfNotSuitableLeads: 0,
    },
    isLoading: false,
  },
  editSuccess: false,
  enrichingData: {},
  messageSendingSuccess: false,
};

const candidatesReducer: Reducer<CandidatesState, CandidatesActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_CANDIDATES_DATA_LOADING: {
      return {
        ...state,
        candidatesList: { ...state.candidatesList, isLoading: true },
      };
    }
    case GET_CANDIDATES_DATA: {
      return {
        ...state,
        candidatesList: { data: action.payload, isLoading: false },
      };
    }
    case GET_CANDIDATES_DATA_ERROR: {
      return {
        ...state,
        candidatesList: { ...state.candidatesList, isLoading: false },
      };
    }
    case SET_CANDIDATE_STATUS: {
      const newCandidates = [...state.candidatesList.data.candidates];
      action.payload.ids.forEach((id) => {
        const index = state.candidatesList.data.candidates.findIndex(
          (el) => el.id === id,
        );
        let status;
        switch (action.payload.status) {
          case CandidateStatusTransitionNames.promote:
            status = CandidateStatus.suitable;
            break;
          case CandidateStatusTransitionNames.devote:
            status = CandidateStatus.not_suitable;
            break;
          case CandidateStatusTransitionNames.reject:
            status = CandidateStatus.rejected;
            break;
          default:
            status = CandidateStatus.none;
            break;
        }
        newCandidates[index] = {
          ...newCandidates[index],
          status,
        };
      });

      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          data: { ...state.candidatesList.data, candidates: newCandidates },
        },
      };
    }
    case SET_CANDIDATE_MESSAGE: {
      const newCandidates = [...state.candidatesList.data.candidates];
      action.payload.to.forEach((email) => {
        const index = state.candidatesList.data.candidates.findIndex(
          (el) => el.email === email,
        );
        newCandidates[index] = {
          ...newCandidates[index],
          messages: [
            action.payload.message,
            ...state.candidatesList.data.candidates[index].messages,
          ],
        };
      });

      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          data: { ...state.candidatesList.data, candidates: newCandidates },
        },
      };
    }
    case SET_CANDIDATE_EDIT_SUCCESS: {
      return {
        ...state,
        editSuccess: true,
      };
    }
    case GET_ENRICHING_DATA: {
      return {
        ...state,
        enrichingData: action.payload,
      };
    }
    case SET_MESSAGE_STATUS: {
      const newMessages = [...state.candidatesList.data.messages];
      action.payload.ids.forEach((id) => {
        const index = state.candidatesList.data.messages.findIndex(
          (el) => el.id === id,
        );
        newMessages[index] = {
          ...newMessages[index],
          status: action.payload.status,
        };
      });

      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          data: { ...state.candidatesList.data, candidates: newMessages },
        },
      };
    }
    case SET_CANDIDATE_NOTE: {
      const index = state.candidatesList.data.candidates.findIndex(
        (el) => el.id === action.payload.candidateId,
      );
      const newCandidates = [...state.candidatesList.data.candidates];
      newCandidates[index] = {
        ...newCandidates[index],
        notes: [action.payload.value, ...newCandidates[index].notes],
      };
      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          data: { ...state.candidatesList.data, candidates: newCandidates },
        },
      };
    }
    case REMOVE_CANDIDATE_NOTE: {
      const index = state.candidatesList.data.candidates.findIndex(
        (el) => el.id === action.payload.candidateId,
      );
      const newCandidates = [...state.candidatesList.data.candidates];
      newCandidates[index] = {
        ...newCandidates[index],
        notes: newCandidates[index].notes.filter(
          (el) => el.id === action.payload.id,
        ),
      };
      return {
        ...state,
        candidatesList: {
          ...state.candidatesList,
          data: { ...state.candidatesList.data, candidates: newCandidates },
        },
      };
    }

    case GET_CANDIDATE_PROFILE_DATA_LOADING: {
      return {
        ...state,
        candidate: { ...state.candidate, isLoading: true },
      };
    }
    case GET_CANDIDATE_PROFILE_DATA: {
      return {
        ...state,
        candidate: { data: action.payload, isLoading: false },
      };
    }
    case GET_CANDIDATE_PROFILE_DATA_ERROR: {
      return {
        ...state,
        candidate: { ...state.candidate, isLoading: false },
      };
    }
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        messageSendingSuccess: action.payload,
      };
    }
    default:
      return state;
  }
};

export default candidatesReducer;
