import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    link: {
      fontSize: 14,
      color: '#000',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      fontSize: 14,
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  }),
);
