import { AgentsItem, ListingError, ListingsItem } from '../../types/entityTypes';

export interface ListingsResponse {
  ['hydra:member']: Array<ListingsItem>,
  ['hydra:totalItems']: number,
};

export type ListingErrorResponse = ListingError;

export interface AdsState {
  data: ListingsResponse;
  isLoading: boolean;
  error: boolean;
  errorMessage: string;
  code: number;
}


export interface AdsFormValues {
  page: number;
  itemsPerPage: number;
  listing?: string | null;
  pagination: boolean
}

export interface GetAdsDataLoading {
  type: typeof GET_ADS_DATA_LOADING;
}
export interface GetAdsData {
  type: typeof GET_ADS_DATA;
  payload: ListingsResponse;
}
export interface GetAdsDataError {
  type: typeof GET_ADS_DATA_ERROR;
  payload: ListingErrorResponse;
}

export const GET_ADS_DATA_LOADING = 'GET_ADS_DATA_LOADING';
export const GET_ADS_DATA = 'GET_ADS_DATA';
export const GET_ADS_DATA_ERROR = 'GET_ADS_DATA_ERROR';

export type AdsActions = GetAdsDataLoading | GetAdsData | GetAdsDataError;
