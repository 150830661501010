import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
      cardContainer: {
        marginTop: '4rem'
      },
      agentCard:{
          marginTop: 10,
          minHeight: '70px',
          padding: '1rem'
      }
  }))