import enMessages from './locale/en-GB';
import nlMessages from './locale/nl-NL';

export enum Locale {
  NL = 'nl-NL',
  EN = 'en-GB',
}

const AppLocale = {
  [Locale.NL]: nlMessages,
  [Locale.EN]: enMessages,
};

export default AppLocale;
