import { IconButton } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

type Props = {
  selected: boolean;
  onClick: () => void;
};

export const StatusButton: React.FC<Props> = ({
  selected,
  children,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <IconButton
      onClick={onClick}
      className={`${classes.button} ${selected ? classes.selected : ''}`}>
      {children}
    </IconButton>
  );
};
