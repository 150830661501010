/* eslint-disable no-param-reassign */
import { AxiosResponse } from 'axios';

import { AgentsItem } from '../../types/entityTypes';
import { request, requestBare, requestJsonLp } from '../utils/request';

function convertToSlug(Text: string) {
  return Text.toLowerCase()
    .replace(/ /g, '_')
    .replace(/[^\w-]+/g, '');
}

export const createAgentConfiguration = async (
  token: string,
  data: any
): Promise<any> => {
  const response = await request(token).post('/agents', {
    ...data,
    systemName: convertToSlug(data.name)
  }
  );
  return response;
};

export const updateAgentConfiguration = async (
  token: string,
  id: string,
  data: any
): Promise<any> => {
  const response = await request(token).put(`/agents/${id}`, {
    ...data,
    systemName: convertToSlug(data.name)
  }
  );
  return response;
};

export const saveAgentLoginEmails = async (
  token: string,
  id: string,
  email: string
): Promise<any> => {
  const response = await request(token).post(`/agent_logins`, {
    login: email,
    agent: `/api/agents/${id}`
  }
  );
  return response.data;
};

export const getAgentLoginEmails = async (
  token: string,
  id: string,
): Promise<any> => {
  const response = await requestBare(token).get(id)
  return response.data;
};


export const removeAgentLoginEmails = async (
  token: string,
  id: number,
): Promise<any> => {
  const response = await request(token).delete(`/agent_logins/${id}`)
  return response.data;
};
