import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Popover,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { sendMessageSuccess } from '../../../../../store/actions/candidatesDataActions';
import { useAppSelector } from '../../../../../store/store';
import { sendMessageThunk } from '../../../../../store/thunks/candidatesThunk';
import { useStyles } from './styles';

type Props = {
  candidatesIds: Array<string>;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  candidatesArray: any;
};

export const BulkMessages: React.FC<Props> = ({
  candidatesIds,
  anchorEl,
  handleClose,
  candidatesArray,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = useTheme();

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const { messageSendingSuccess } = useAppSelector((state) => state.candidates);

  useEffect(() => {
    if (messageSendingSuccess) {
      handleClose();
    }
  }, [handleClose, messageSendingSuccess]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handler: (value: string) => void,
  ) => {
    handler(event.target.value);
  };

  const formatCandidatesName = (): string => {
    const accum = candidatesIds.map((el) =>
      candidatesArray.find((candidate: any) => candidate.id === el),
    );
    if(accum[0] === undefined){
      return '[]'
    }
      const resultArray =  accum.map((el) => `${el.firstName} ${el.lastName}`);
      return resultArray.join(', ');
  };

  const formatcandidateEmail = (): Array<string> => {
    const accum = candidatesIds.map((el) =>
      candidatesArray.find((candidate: any) => candidate.id === el),
    );
    return accum.map((el) => el.id);
  };

  const {token} = useAppSelector((state) => state.user)

  const sendMessage = () => {
    dispatch(
      sendMessageThunk(token, {
        to: formatcandidateEmail(),
        message: { subject, body },
      }),
    );
  };

  const handleConfirmClose = () => {
    setConfirmModalOpen(false);
    handleClose();
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setConfirmModalOpen(true)}
        onBackdropClick={() => setConfirmModalOpen(true)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { maxWidth: theme.breakpoints.values.lg },
        }}>
        <div className={classes.root}>
          <Typography variant="h3" className={classes.title}>
            Bulkbericht versturen
          </Typography>
          <Grid container spacing={0} className={classes.headerContainer}>
            <Grid item xs={3} className={classes.containerWithBotMargin}>
              <Typography variant="body1" className={classes.descriptionTitle}>
                Afzender:
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.containerWithBotMargin}>
              <Typography>
                {user?.name}({user?.email})
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.containerWithBotMargin}>
              <Typography variant="body1" className={classes.descriptionTitle}>
                Geaddresseerden (BCC):
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.containerWithBotMargin}>
              <Typography>{formatCandidatesName()}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.sebjectTitleContainer}>
              <Typography variant="body1" className={classes.descriptionTitle}>
                Onderwerp:
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                <TextField
                  id="outlined-textarea"
                  placeholder="Onderwerpregel"
                  variant="outlined"
                  value={subject}
                  onChange={(e) => handleInputChange(e, setSubject)}
                  className={classes.subjectInput}
                />
              </Typography>
            </Grid>
          </Grid>
          <TextField
            id="outlined-multiline-static"
            placeholder="Bericht schrijven.."
            multiline
            rows={8}
            variant="outlined"
            className={classes.bodyInput}
            value={body}
            onChange={(e) => handleInputChange(e, setBody)}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              onClick={() => setConfirmModalOpen(true)}>
              Annuleren
            </Button>
            <Button
              variant="contained"
              disabled={!subject || !body}
              onClick={sendMessage}
              className={classes.submitBtn}>
              Verstuur bericht
            </Button>
          </div>
        </div>
      </Popover>
      <Modal
        className={classes.modal}
        open={confirmModalOpen}
        disableAutoFocus
        BackdropProps={{ style: { opacity: 0 } }}>
        <Fade in={confirmModalOpen}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.modalTitle}>
              Annuleren
            </Typography>
            <Typography variant="body1">
              Weet u zeker dat u deze actie wilt annuleren?
            </Typography>
            <div className={classes.buttonContainer}>
              <Button variant="text" onClick={() => setConfirmModalOpen(false)}>
                Nee
              </Button>
              <Button variant="text" onClick={handleConfirmClose}>
                Ja
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};
