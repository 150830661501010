import { nlNL } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';

/**
 * material-ui theme color pallete
 * @see https://material-ui.com/style/color/
 */
export const MuiTheme = createTheme(
  {
    palette: {
      primary: {
        light: '#D3D3D3',
        main: '#000',
        dark: '#0000',
      },
      secondary: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'initial',
        },
      },
    },
  },
  nlNL,
);
