import {
  REMOVE_TOKEN,
  SET_TOKEN,
  TokenString,
  UserActions
} from '../types/user';

export const setUserToken = (payload: TokenString): UserActions => {
  return {
    type: SET_TOKEN,
    payload,
  };
};

export const removeUserToken = (): UserActions => {
  return {
    type: REMOVE_TOKEN,
  };
};
