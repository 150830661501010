import { Container } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../store/store';
import { HeaderTab } from '../../../types/entityTypes';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import LeadHeader from '../../components/LeadHeader/LeadHeader';
import { MessagesTab } from '../../components/MessagesTab/MessagesTab';
import { ProfileTab } from './components/ProfileTab/ProfileTab';

const CandidatePage: React.FC = () => {
  const { data, isLoading } = useAppSelector(
    (state) => state.candidates.candidatesList,
  );
  const { headerTab } = useAppSelector((state) => state.ui);
  const { data: candidate } = useAppSelector(
    (state) => state.candidates.candidate,
  );
  const { editCandidateOpen } = useAppSelector((state) => state.ui);

  const { id: leadId, candidateId } = useParams() as {
    id: string;
    candidateId: string;
  };

  const breadcrumplsLinks = [
    {
      href: `/dashboard/${leadId}`,
      title: `${data.street} ${data.houseNumber}, ${data.city}`,
    },
    {
      href: `/dashboard/${leadId}`,
      title: headerTab === HeaderTab.CANDIDATE ? 'Kandidaten' : 'Berichten',
    },
    {
      href: `/dashboard/${leadId}/${candidateId}`,
      title: `${candidate.firstName} ${candidate.lastName}`,
    },
  ];

  editCandidateOpen &&
    breadcrumplsLinks.push({ title: 'Kandidaat bewerken', href: '' });

  return (
    <Container maxWidth="lg">
      {!isLoading && <Breadcrumbs links={breadcrumplsLinks} />}
      <LeadHeader data={data} hideTab={false} />
      {headerTab === HeaderTab.CANDIDATE ? <ProfileTab /> : <MessagesTab />}
    </Container>
  );
};

export default CandidatePage;
