import {
  Backdrop,
  Button,
  Fade,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';

import greenStar from '../../../../../greenStar.svg';
import { useStyles } from './styles';

type Props = {
  isOpen: boolean;
};

export const SuccessModal: React.FC<Props> = ({ isOpen }) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      hideBackdrop
      disableAutoFocus>
      <Fade in={isOpen}>
        <Paper className={classes.paper}>
          <img src={greenStar} alt="success icon" />
          <Typography variant="h5" className={classes.title}>
            Informatie is succesvol gedeeld met de makelaar
          </Typography>
          <Typography variant="body1" align="center">
            De makelaar gaat je gegevens doornemen om te kijken of er een match
            is met de woning.
          </Typography>
          <Typography variant="h5" className={classes.title}>
            Information successfully shared with agent
          </Typography>
          <Typography variant="body1" align="center">
            The agent will assess whether your profile matches with the listing.
          </Typography>
        </Paper>
      </Fade>
    </Modal>
  );
};
