import { Button, Grid, Paper } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Locale } from '../../../config/I18n';
import { setLocale } from '../../../store/actions/uiActions';
import { useAppSelector } from '../../../store/store';
import {
  getEnrichingDataThunk,
  sendCandidateDataThunk,
} from '../../../store/thunks/candidatesThunk';
import { CandidateFormValues } from '../../../store/types/candidates';
import { EnrichingFormComponent } from './components/EnrichingFormComponent/EnrichingFormComponent';
import { SuccessModal } from './components/SuccessModal/SuccessModal';
import { useStyles } from './styles';

const EnrichingForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: any = useParams();

  const locale = useAppSelector((state) => state.ui.locale);
  const { editSuccess, enrichingData } = useAppSelector(
    (state) => state.candidates,
  );
  const {token} = useAppSelector((state) => state.user)


  const handleFormSubmit = (values: CandidateFormValues) => {
    const { id } = params;
    dispatch(sendCandidateDataThunk(token, { id, values }));
  };

  useEffect(() => {
    const uid = params.id;
    dispatch(getEnrichingDataThunk(token, uid));
  }, [dispatch]);

  useEffect(() => {
  }, [enrichingData])

  return (
    <Container className={classes.container} maxWidth="lg">
      <div className={classes.languageSwitch}>
        <LanguageIcon fontSize="small" />
        <Button
          className={
            locale === Locale.NL ? classes.activeBtn : classes.inactiveBtn
          }
          variant="text"
          onClick={() => dispatch(setLocale(Locale.NL))}>
          Nederlands
        </Button>
        |
        <Button
          className={
            locale === Locale.EN ? classes.activeBtn : classes.inactiveBtn
          }
          variant="text"
          onClick={() => dispatch(setLocale(Locale.EN))}>
          English
        </Button>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <div className={classes.agentContainer}>
            <div className={classes.logo}>
              <img
                src={`${
                  enrichingData.agent
                    ? enrichingData.agent.details.logo
                    : ''
                }`}
                width={180}
                alt="Makelaar logo"
              />
            </div>
            <Typography variant="body1">
              {enrichingData.agent
                ? enrichingData.agent.details.name
                : 'Company Name'}
            </Typography>
          </div>
          <Typography variant="h3" className={classes.title}>
            <FormattedMessage
              id="enriching.title"
              values={{
                address: `${
                  enrichingData.details
                    ? enrichingData.details.street
                    : 'Streetname'
                }`,
                houseNumber: `${
                  enrichingData.details &&
                  enrichingData.agent.details.show_house_number ? enrichingData.details.house_number : ''
                }`
              }}
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id="enriching.body"
              values={{
                p: (chunks: string) => <p>{chunks}</p>,
                br: (
                  <>
                    <br />
                    <br />
                  </>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.formContainer}>
            <EnrichingFormComponent
              handleSubmit={handleFormSubmit}
              agentDetails={enrichingData.agent ? enrichingData.agent.details : enrichingData}
            />
          </Paper>
        </Grid>
      </Grid>
      <SuccessModal isOpen={editSuccess} />
    </Container>
  );
};

export default EnrichingForm;
