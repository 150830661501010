import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../store/store';
import { getCandidatesThunk } from '../../../store/thunks/candidatesThunk';
import { HeaderTab } from '../../../types/entityTypes';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import LeadHeader from '../../components/LeadHeader/LeadHeader';
import { MessagesTab } from '../../components/MessagesTab/MessagesTab';
import { CreateCandidateTab } from './components/CreateCandidateTab/CreateCandidateTab';

const CreateCandidate: React.FC = () => {
  const { data, isLoading } = useAppSelector(
    (state) => state.candidates.candidatesList,
  );
  const {token} = useAppSelector((state) => state.user);
  const { headerTab } = useAppSelector((state) => state.ui);

  const dispatch = useDispatch();

  const { id } = useParams() as { id: string };

  useEffect(() => {
    dispatch(getCandidatesThunk(token, id));
  }, [dispatch, id]);

  return (
    <Container maxWidth="lg">
      {!isLoading && (
        <Breadcrumbs
          links={[
            {
              href: `/dashboard/${id}`,
              title: `${data.street} ${data.houseNumber}, ${data.city}`,
            },
            {
              title:
                headerTab === HeaderTab.CANDIDATE
                  ? 'Kandidaat toevoegen'
                  : 'Berichten',
            },
          ]}
        />
      )}
      <LeadHeader data={data} hideTab />
      {headerTab === HeaderTab.CANDIDATE ? (
        <CreateCandidateTab />
      ) : (
        <MessagesTab />
      )}
    </Container>
  );
};

export default CreateCandidate;
