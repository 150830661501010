import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    header: {
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    aptHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: 24,
      height: 200
    },
    aptImg: {
      backgroundColor: '#b3b3b3',
      height: 50,
      width: 100,
      marginRight: 16,
    },
    chipListing:{
      textDecoration: 'none',
      marginLeft: 14,
      fontSize: 16
    },
    tabsContainer: {
      marginTop: 40,
      marginBottom: 20
    },
    aptInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    tabLabelText: {
      textTransform: 'none',
    },
    switchContainer:{
      width: '100%',
      height: 200
    }
  }),
);
