import { Grid, Paper } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Locale } from '../../../../../config/I18n';
import {
  setLocale,
  toggleProfileEdit,
} from '../../../../../store/actions/uiActions';
import { useAppSelector } from '../../../../../store/store';
import { sendCandidateDataThunk } from '../../../../../store/thunks/candidatesThunk';
import {
  Candidate,
  CandidateFormValues,
} from '../../../../../store/types/candidates';
import CandidateForm from '../../../../components/CandidateForm/CandidateForm';
import { useStyles } from './styles';
import { validationSchema } from './validation';

interface Props {
  data: Candidate;
}

export const EditCandidate: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const { id: leadId, candidateId } = useParams() as {
    id: string;
    candidateId: string;
  };

  const history = useHistory();

  const handleRedirect = () => history.push(`/dashboard/${leadId}`);

  const {token} = useAppSelector((state) => state.user)

  const handleSubmit = (values: CandidateFormValues) => {
    dispatch(
      sendCandidateDataThunk(token, { id: candidateId, values }),
    );
    dispatch(toggleProfileEdit(false));
  };

  const handleCloseForm = () => {
    dispatch(toggleProfileEdit(false));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLocale(Locale.NL));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      birthday: null,
      gender: '',
      employment: '',
      income: data ? data.income : null,
      guarantor: '',
      householdType: data.household,
      household: '',
      availableSince: null,
      term: null,
      pets: data.pets === undefined && null,
      startDate: null,
    },
    validationSchema,
    onSubmit: ({ pets, ...values }) => {
      const updatedValues = {
        ...values,
        pets: typeof pets === 'string' ? pets === 'true' : null,
      };
      handleSubmit(updatedValues);
    },
  });

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.formContainer}>
          <CandidateForm
            values={formik.values}
            errors={formik.errors}
            handleSubmit={formik.handleSubmit}
            handleChange={formik.handleChange}
            isValid={formik.isValid}
            creation
            handleCloseForm={handleCloseForm}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
