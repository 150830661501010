import { Button, Popover } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../store/store';
import {
  changeCandidateStatusThunk,
  changeMessageStatusThunk,
} from '../../../../../store/thunks/candidatesThunk';
import { CandidateStatusTransitionNames } from '../../../../../store/types/candidates';
import { MessagesStatus } from '../../../../../types/entityTypes';
import { useStyles } from './styles';

type Props = {
  handleBulkStatusChange?: any;
  anchorEl: HTMLButtonElement | null;
  handleClose: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  openMessages: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  openExtraInformation: () => void;
  openStatusModal: () => void;
  selectedRows: { candidates: Array<string>; messages?: Array<string> };
  messages: boolean;
};

export const BulkActionsPopover: React.FC<Props> = ({
  handleBulkStatusChange,
  anchorEl,
  handleClose,
  openMessages,
  openExtraInformation,
  selectedRows,
  messages,
  openStatusModal,
}) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'bulk-popover' : undefined;

  const dispatch = useDispatch();
  const { token } = useAppSelector((state) => state.user);

  const handleMessagesStatusChange = (status: MessagesStatus) => {
    dispatch(
      changeMessageStatusThunk(token, {
        ids: selectedRows.messages as Array<string>,
        status,
      }),
    );
  };

  const handleMessagesClick = () => {
    handleClose(null);
    openMessages(anchorEl);
  };

  // const handleStatusChange = (status: CandidateStatusTransitionNames) => {
  //   console.log('selected ROws', status);
  //   dispatch(
  //     changeCandidateStatusThunk(token, {
  //       ids: selectedRows.candidates,
  //       status,
  //     }),
  //   );
  // };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 64,
      }}>
      <div className={classes.root}>
        <Button
          disabled={!selectedRows.candidates.length}
          onClick={handleMessagesClick}
          variant="text">
          Bericht sturen
        </Button>
        <Button
          disabled={!selectedRows.candidates.length}
          onClick={() => {
            handleClose(null);
            openExtraInformation();
          }}
          variant="text">
          Extra informatie opvragen
        </Button>
        {!messages ? (
          <>
            <Button
              disabled={!selectedRows.candidates.length}
              variant="text"
              onClick={() =>
                handleBulkStatusChange(
                  selectedRows.candidates,
                  CandidateStatusTransitionNames.reset,
                )
              }>
              Markeren als te beoordelen
            </Button>
            <Button
              disabled={!selectedRows.candidates.length}
              variant="text"
              onClick={() =>
                handleBulkStatusChange(
                  selectedRows.candidates,
                  CandidateStatusTransitionNames.promote,
                )
              }>
              Markeren als geschikt
            </Button>
            <Button
              disabled={!selectedRows.candidates.length}
              variant="text"
              onClick={() =>
                handleBulkStatusChange(
                  selectedRows.candidates,
                  CandidateStatusTransitionNames.devote,
                )
              }>
              Markeren als ongeschikt
            </Button>
            <Button
              disabled={!selectedRows.candidates.length}
              variant="text"
              onClick={() =>
                handleBulkStatusChange(
                  selectedRows.candidates,
                  CandidateStatusTransitionNames.reject,
                )
              }>
              Markeren als afgewezen
            </Button>
          </>
        ) : (
          <>
            <Button
              disabled={!selectedRows.candidates.length}
              onClick={() => {
                handleClose(null);
                handleMessagesStatusChange(MessagesStatus.read);
              }}
              variant="text">
              Markeren als gelezen
            </Button>
            <Button
              disabled={!selectedRows.candidates.length}
              onClick={() => {
                handleClose(null);
                handleMessagesStatusChange(MessagesStatus.unread);
              }}
              variant="text">
              Markeren als ongelezen
            </Button>
          </>
        )}
      </div>
    </Popover>
  );
};
