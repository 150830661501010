import { Grid, Paper } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Locale } from '../../../../../config/I18n';
import { setLocale } from '../../../../../store/actions/uiActions';
import { useAppSelector } from '../../../../../store/store';
import { createCandidateThunk } from '../../../../../store/thunks/candidatesThunk';
import { CandidateFormValues } from '../../../../../store/types/candidates';
import CandidateForm from '../../../../components/CandidateForm/CandidateForm';
import { useStyles } from './styles';
import { validationSchema } from './validation';

export const CreateCandidateTab: React.FC = () => {
  const classes = useStyles();

  const { id } = useParams() as { id: string };

  const history = useHistory();

  const handleRedirect = () => history.push(`/dashboard/${id}`);
  const {token} = useAppSelector((state) => state.user);
  const handleSubmit = (values: CandidateFormValues) => {
    dispatch(createCandidateThunk(token, { id, values }, handleRedirect));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLocale(Locale.NL));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      birthday: null,
      gender: '',
      employment: '',
      income: 0,
      guarantor: '',
      household: '',
      householdType: '',
      pets: null,
      availableSince: null,
      term: null,
    },
    validationSchema,
    onSubmit: ({ pets, ...values }) => {
      const updatedValues = {
        ...values,
        pets: typeof pets === 'string' ? pets === 'true' : null,
      };
      console.log(JSON.stringify(updatedValues, null, 2));
      handleSubmit(updatedValues);
    },
  });

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.formContainer}>
          <CandidateForm
            handleCloseForm={() => console.log('Closing form')}
            values={formik.values}
            errors={formik.errors}
            handleSubmit={formik.handleSubmit}
            handleChange={formik.handleChange}
            isValid={formik.isValid}
            creation
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
