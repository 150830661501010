import {
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { setHeaderTab } from '../../../store/actions/uiActions';
import { useAppSelector } from '../../../store/store';
import { editAutomaticEnriching } from '../../../store/thunks/adsThunk';
import { getCandidatesThunk } from '../../../store/thunks/candidatesThunk';
import { Lead } from '../../../store/types/candidates';
import { HeaderTab } from '../../../types/entityTypes';
import { useStyles } from './styles';

interface Props {
  data: Lead;
  hideTab: boolean;
}

const AppHeader: React.FC<Props> = ({ data, hideTab }) => {
  const classes = useStyles();

  const { headerTab } = useAppSelector((state) => state.ui);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useLocation();
  const path = params.pathname;
  const { token } = useAppSelector((state) => state.user);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    dispatch(setHeaderTab(value as HeaderTab));
  };



  const handleAutoEnrichingToggle = () => {
    new Promise(function (resolve, reject) {
      if (data.details.enrichment === 'manual') {
        resolve(dispatch(editAutomaticEnriching(token, 'instant', [data.id])));
      } else {
        resolve(dispatch(editAutomaticEnriching(token, 'manual', [data.id])));
      }
    }).then(function (result) {
      dispatch(getCandidatesThunk(token, data.id));
    });
  };

  return (
    <Grid container spacing={0} className={classes.header}>
      <Grid item xl={6}>
        <div className={classes.aptHeader}>
          <div className={classes.aptInfo}>
            <Typography variant="h5">   <span>{data.street} {data.houseNumber}, {data.city}</span> 
            </Typography>
            <Typography variant="subtitle1">
              &euro;
              {` ${data.price} - ${data.surface} m2 - ${data.surface} kamers`}
           
            </Typography>
            <div className={classes.tabsContainer}>
              <Tabs value={headerTab} onChange={handleTabChange}>
                <Tab
                  label={
                    <div className={classes.tabLabel}>
                      <AccountCircleOutlinedIcon />
                      <Typography
                        className={classes.tabLabelText}
                        variant="body1">
                        Kandidaten
                      </Typography>
                    </div>
                  }
                />
                <Tab
                  label={
                    <div className={classes.tabLabel}>
                      <ChatOutlinedIcon />
                      <Typography
                        className={classes.tabLabelText}
                        variant="body1">
                        {' '}
                        Berichten
                      </Typography>
                    </div>
                  }
                />
              </Tabs>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xl={6}>
        <div className={classes.switchContainer}>
          <Tooltip
            title="Met deze instelling worden kandidaten automatisch uitgenodigd om extra informatie aan te leveren."
            placement="top-start">
            <Button>
              <HelpOutlineIcon />
            </Button>
          </Tooltip>
          <FormControlLabel
            labelPlacement="start"
            label="Automatisch informatie opvragen"
            control={
              <Switch
                checked={data.details.enrichment === 'instant'}
                onChange={() => handleAutoEnrichingToggle()}
              />
            }
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default AppHeader;
