import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import EuroIcon from '@material-ui/icons/Euro';
import PeopleIcon from '@material-ui/icons/People';
import PetsIcon from '@material-ui/icons/Pets';
import RemoveIcon from '@material-ui/icons/Remove';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ExtraInfoModal } from '../../ExtraInfoModal/ExtraInfoModal';
import { useStyles } from '../styles';

type Props = {
  candidate: any;
  closeExtraInfoModal: Function;
  setSelectedRows: Function;
  setExtraInformationOpen: Function;
  handleEnrichingByButtonClick: Function;
};

export const CandidatesDataCell: React.FC<Props> = ({
  candidate,
  closeExtraInfoModal,
  setSelectedRows,
  setExtraInformationOpen,
  handleEnrichingByButtonClick,
}) => {
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const [extraInfoOpen, setExtraInfoOpen] = useState(false);

  const classes = useStyles();
  const renderTerm = (term: number | string) => {
    switch (term) {
      case 0:
        return 'Onbepaalde tijd';
      case 3:
        return '0-3 maanden';
      case 6:
        return '3-6 maanden';
      case 12:
        return '6-12 maanden';
      case 24:
        return '1-2 jaar';
      case 48:
        return '2+ jaar';
      case null:
        return '-';
      default:
        return '-';
    }
  };

 
  const renderHousehold = (householdType: string) => {
    let type = null;

    switch (householdType) {
      case 'WITH_FAMILY':
        type = 'Gezin';
        break;
      case 'SINGLE':
        type = 'Alleen';
        break;
      case 'WITH_FRIENDS':
        type = 'Vrienden';
        break;
      case 'WITH_PARTNER':
        type = 'Partner';
        break;
      default:
        type = '-';
    }

    if (type) {
      return type;
    }
  };

  const renderGuarantor = (g: string) => {
    switch (g) {
      case 'ABROAD':
        return 'Buitenland';
      case 'IN_NETHERLANDS':
        return 'Nederland';
      case 'NO_GUARANTOR':
        return 'Geen';
      default:
        return '-';
    }
  };

  const renderPets = (p: null | undefined | boolean) => {
    switch (p) {
      case null:
        return '-';
      case undefined:
        return '-';
      case true:
        return 'Ja';
      case false:
        return 'Nee';
      default:
        return '-';
    }
  };

  const renderDate = (dateString: string) => {
    const d = new Date(dateString);
    const parsedDate = d.toLocaleDateString('nl-NL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    return parsedDate;
  };

  useEffect(() => {}, [extraInfoOpen]);

  return (
    <TableCell>
      {candidate.income === 0 ? (
        <>
          {candidate.status === 'none' || !candidate.formLastSentOn ? (
                <Button
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    handleEnrichingByButtonClick(candidate.id)
                  }
                  disabled={candidate.formLastSentOn}
                  variant="contained"
                  className={
                     candidate.formLastSentOn ? classes.activeBtn : undefined
                  }>
                  Extra informatie opvragen
                </Button>
          ) : (
                <Button
                  disabled={candidate.formLastSentOn}
                  variant="contained"
                  className={
                    candidate.formLastSentOn ? classes.activeBtn : undefined
                  }>
                  Extra informatie is opgevraagd
                </Button>
          )}
        </>
      ) : (
        <>
          <TableCell style={{width: '150px', border: 0}}>
            <Typography className={classes.tableText} variant="body1">
              <EuroIcon fontSize="small" className={classes.icon} />{' '}
              {candidate.income >= 5000
                ? `${candidate.income} +`
                : candidate.income}
            </Typography>
            <Typography className={classes.tableText} variant="body1">
              <AccountBalanceIcon fontSize="small" className={classes.icon} />
              <span>{`${renderGuarantor(candidate.guarantor)}`}</span>
            </Typography>
          </TableCell>
          <TableCell style={{width: '150px', border: 0}}>
            <Typography className={classes.tableText} variant="body1">
              <AccessTimeIcon fontSize="small" className={classes.icon} />
              <span>{`${renderTerm(candidate.term)}`}</span>
            </Typography>
            <Typography className={classes.tableText} variant="body1">
              <CalendarTodayIcon fontSize="small" className={classes.icon} />{' '}
              {candidate.availableSince ? (
                <span>{`${renderDate(candidate.availableSince)}`}</span>
              ) : (
                '-'
              )}
            </Typography>
          </TableCell>
          <TableCell style={{width: '150px', border: 0}}>
            <Typography className={classes.tableText} variant="body1">
              <PeopleIcon fontSize="small" className={classes.icon} />{' '}
              {candidate.household ? (
                <span>{`${renderHousehold(candidate.householdType)} (${
                  candidate.household
                })`}</span>
              ) : (
                '-'
              )}
            </Typography>
            <Typography className={classes.tableText} variant="body1">
              <PetsIcon fontSize="small" className={classes.icon} />{' '}
              <span>{renderPets(candidate.pets)}</span>
            </Typography>
          </TableCell>
        </>
      )}
   </TableCell>
  );
};
