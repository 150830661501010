import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: '25px 42px',
      backgroundColor: '#F0F0F0',
      maxWidth: 520,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 47,
    },
    subtitle: {
      fontSize: 18,
      marginBottom: 24,
    },
    checkboxContainer:{
      marginTop: 10,
      marginBottom: 10
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 20,
      textAlign: 'right',
      '& > button': {
        fontWeight: 'bold',
        fontSize: 18,
      },
    },
  }),
);
