import { AgentsItem, ListingError } from '../../types/entityTypes';

export type AgentErrorResponse = any
export interface AgentsResponse {
  ['hydra:member']: Array<AgentsItem>,
  ['hydra:totalItems']: number,
}


export type AgentsDetailsResponse = AgentsItem

export interface AgentsState {
  data: AgentsResponse;
  isLoading: boolean;
  error: boolean;
  errorMessage: string;
  code: number;
  selectedAgent: AgentsItem
}

export interface GetAgentsDetailsData {
  type: typeof GET_AGENTS_DETAILS_DATA,
  payload: AgentsDetailsResponse
}
export interface GetAgentsDataLoading {
  type: typeof GET_AGENTS_DATA_LOADING;
}
export interface GetAgentsData {
  type: typeof GET_AGENTS_DATA;
  payload: AgentsResponse;
}
export interface GetAgentsDataError {
  type: typeof GET_AGENTS_DATA_ERROR;
  payload: AgentErrorResponse;
}
export interface SetEmailForInquiry {
  type: typeof SET_EMAIL_INQUIRY;
  payload: any
}


export interface RemoveEmailForInquiry {
  type: typeof REMOVE_EMAIL_INQUIRY;
  payload: any
}

export interface SetIdForLeads {
  type: typeof SET_LEADS_ID;
  payload: any
}


export interface RemoveIdForLeads {
  type: typeof REMOVE_LEADS_ID;
  payload: any
}


export interface RemoveLogin {
  type: typeof REMOVE_LOGIN;
  payload: any
}

export interface SetLogin {
  type: typeof SET_LOGIN;
  payload: any
}

export interface SetLoginsFromApi {
  type: typeof SET_LOGINS_FROM_API;
  payload: any
}

export const GET_AGENTS_DETAILS_DATA = 'GET_AGENTS_DETAILS_DATA'
export const GET_AGENTS_DATA_LOADING = 'GET_AGENTS_DATA_LOADING';
export const GET_AGENTS_DATA = 'GET_AGENTS_DATA';
export const GET_AGENTS_DATA_ERROR = 'GET_AGENTS_DATA_ERROR';
export const SET_EMAIL_INQUIRY = 'SET_EMAIL_INQUIRY'
export const REMOVE_EMAIL_INQUIRY = 'REMOVE_EMAIL_INQUIRY'
export const SET_LEADS_ID = 'SET_LEADS_ID'
export const REMOVE_LEADS_ID = 'REMOVE_LEADS_ID'
export const SET_LOGINS_FROM_API = 'SET_LOGINS_FROM_API'
export const SET_LOGIN = 'SET_LOGIN'
export const REMOVE_LOGIN = 'REMOVE_LOGIN'

export type AgentActions = GetAgentsData | GetAgentsDataLoading | SetIdForLeads | RemoveIdForLeads | GetAgentsDetailsData | RemoveEmailForInquiry | SetEmailForInquiry | GetAgentsDataError | RemoveLogin | SetLogin | SetLoginsFromApi;
