import { Button, Grid, Paper, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AllInboxOutlinedIcon from '@material-ui/icons/AllInboxOutlined';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import EuroIcon from '@material-ui/icons/Euro';
import PeopleIcon from '@material-ui/icons/People';
import PetsIcon from '@material-ui/icons/Pets';
import PhoneIcon from '@material-ui/icons/Phone';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  setHeaderTab,
  toggleProfileEdit,
} from '../../../../../store/actions/uiActions';
import { useAppSelector } from '../../../../../store/store';
import { changeCandidateStatusThunk } from '../../../../../store/thunks/candidatesThunk';
import {
  Candidate,
  CandidateStatusTransitionNames,
} from '../../../../../store/types/candidates';
import { CandidateStatus, HeaderTab } from '../../../../../types/entityTypes';
import { StatusButton } from '../../../Dashboard/components/StatusButton/StatusButton';
import { Actions } from '../Actions/Actions';
import { Messages } from '../Messages/Messages';
import { Notes } from '../Notes/Notes';
import { useStyles } from './styles';

interface Props {
  data: Candidate;
}

export const ProfileInfo: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const { id: leadId, candidateId } = useParams() as {
    id: string;
    candidateId: string;
  };

  const history = useHistory();

  const dispatch = useDispatch();

  const handleMessagesRedirect = () => {
    dispatch(setHeaderTab(HeaderTab.MESSAGES));
  };

  const handleOpenForm = () => {
    dispatch(toggleProfileEdit(true));
  };

  const {token} = useAppSelector((state) => state.user)
  
  const handleStatusChange = (
    id: string,
    status: CandidateStatusTransitionNames,
  ) => {
    dispatch(changeCandidateStatusThunk(token, { ids: [id], status }));
  };

  const renderTerm = (term: number | string) => {
    switch (term) {
      case 0: return 'Onbepaalde tijd';
      case 3:
        return '0-3 maanden';
      case 6:
        return '3-6 maanden';
      case 12:
        return '6-12 maanden';
      case 24:
        return '1-2 jaar';
      case 48:
        return '2+ jaar';
      case null:
        return '-';
      default:
        return '-';
    }
  };

  const renderHousehold = (householdType: string) => {
    let type = null;

    switch (householdType) {
      case 'WITH_FAMILY':
        type = 'Gezin';
        break;
      case 'SINGLE':
        type = 'Alleen';
        break;
      case 'WITH_FRIENDS':
        type = 'Vrienden';
        break;
      case 'WITH_PARTNER':
        type = 'Partner';
        break;
      default:
        type = undefined;
    }

    if (type) {
      return type;
    }
  };

  const renderGuarantor = (g:string) => {
    switch(g){
      case 'ABROAD': return "Buitenland"
      case 'IN_NETHERLANDS': return 'Nederland'
      case 'NO_GUARANTOR': return 'Geen'
      default: return "-"
    }
  }

  const renderDate = (dateString: string) => {
    const d = new Date(dateString);
    const parsedDate = d.toLocaleDateString('nl-NL', { day: '2-digit',month: '2-digit', year: 'numeric'  })
    return parsedDate
  }

  const renderPets = (p:null|undefined|boolean) =>{
    switch(p){
      case null: return '-'
      case undefined: return '-'
      case true: return 'Ja'
      case false: return 'Nee'
      default: return '-'
    }
   }
 
  const parseOrigin = (origin:string) => {
    switch(origin){
      case "huurwoningen": return "Huurwoningen"
      case "pararius": return "Pararius"
      default: return "Onbekend"
    }
  }

  return (
    <>
      <div className={classes.navButtonContainer}>
        <Button
          onClick={() => history.push(`/dashboard/${leadId}`)}
          startIcon={<AccountCircleOutlinedIcon />}
          variant="outlined">
          Alle kandidaten
        </Button>
        <Button
          onClick={handleMessagesRedirect}
          startIcon={<AllInboxOutlinedIcon />}
          variant="outlined">
          Alle berichten
        </Button>
      </div>
      <Paper className={classes.mainCandidateInfo}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography
              className={classes.candidateName}
              variant="h6">{`${data.firstName} ${data.lastName}`}</Typography>
            <Typography className={classes.candidateDescr} variant="body1">
              Man, Werkzaam voor werkgever (mock)
            </Typography>
            <Typography className={classes.txtWithIco} variant="body1">
              <AlternateEmailIcon fontSize="small" /> {data.email}
            </Typography>
            <Typography className={classes.txtWithIco} variant="body1">
              <PhoneIcon fontSize="small" /> {data.phone}
            </Typography>
          </Grid>
          <Grid container item xs={4} className={classes.textIconsContainer}>
            <Grid item xs={4}>
              <Typography className={classes.txtWithIco} variant="body1">
                <EuroIcon />
                {data.income >= 5000 ? `${data.income} +` : data.income}
              </Typography>
              <Typography className={classes.txtWithIco} variant="body1">
                <AccountBalanceIcon />
                {data.guarantor ? <span>{`${renderGuarantor(data.guarantor)}`}</span> : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.txtWithIco} variant="body1">
                <AccessTimeIcon fontSize="small" />{' '}
                {data.term != null ? <span>{`${renderTerm(data.term)}`}</span> : '-'}
              </Typography>
              <Typography className={classes.txtWithIco} variant="body1">
                <CalendarTodayIcon fontSize="small" />{' '}
                {data.availableSince
                  ? <span>{`${renderDate(data.availableSince)}`}</span>
                   
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.txtWithIco} variant="body1">
                <PeopleIcon fontSize="small" />{' '}
                {data.household ? (
                  <span>{`${renderHousehold(data.householdType)} (${
                    data.household
                  })`}</span>
                ) : (
                  '-'
                )}
              </Typography>
              <Typography className={classes.txtWithIco} variant="body1">
                <PetsIcon fontSize="small" /><span>{renderPets(data.pets)}</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.candidateSource}>
                Bron kandidaat: mock
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.candidateButtonsBlock}>
            <div className={classes.statusButtonsContainer}>
              <StatusButton
                onClick={() =>
                  handleStatusChange(
                    data.id,
                    CandidateStatusTransitionNames.promote,
                  )
                }
                selected={data.status === CandidateStatus.suitable}>
                <ThumbUpOutlinedIcon />
              </StatusButton>
              <StatusButton
                onClick={() =>
                  handleStatusChange(
                    data.id,
                    CandidateStatusTransitionNames.devote,
                  )
                }
                selected={data.status === CandidateStatus.not_suitable}>
                <ThumbDownOutlinedIcon />
              </StatusButton>
              <StatusButton
                onClick={() =>
                  handleStatusChange(
                    data.id,
                    CandidateStatusTransitionNames.reject,
                  )
                }
                selected={data.status === CandidateStatus.rejected}>
                <ClearOutlinedIcon />
              </StatusButton>
            </div>
            <Actions id={candidateId} openEditForm={handleOpenForm} />
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Messages candidate={data} />
        </Grid>
        <Grid item xs={4}>
          <Notes notes={data.notes} />
        </Grid>
      </Grid>
    </>
  );
};
