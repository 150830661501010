import { AxiosResponse } from 'axios';

import { CandidateFilterUrlParams, Lead } from '../types/candidates';
import { request, requestJsonLp, urlWithParam } from '../utils/request';

export const fetchCandidates = async (
  token: string,
  id: string,
  filtersValues: CandidateFilterUrlParams | any,
): Promise<AxiosResponse<Lead>> => {
  const urlWithParamsLeads = urlWithParam(
    `/lead_listings/${id}`,
    filtersValues,
  );
  const { data } = await request(token).get(urlWithParamsLeads);

  const candidates = await requestJsonLp(token).get(
    `/candidates?listing=${id}`,
    {
      params: {
        ...filtersValues,
      },
    },
  );
  data.candidates = candidates.data['hydra:member'];
  data.totalAmountCandidates = candidates.data['hydra:totalItems'];

  return data;
};
