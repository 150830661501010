import { useAuth0 } from '@auth0/auth0-react';
import {
  CircularProgress,
  Container,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Locale } from './config/I18n';
import IntlContainer from './containers/IntlContainer';
import { setLocale } from './store/actions/uiActions';
import { useAppSelector } from './store/store';
import AppHeader from './views/components/AppHeader/AddHeader';
import Snackbar from './views/components/Snackbar/Snackbar';
import AdminHome from './views/pages/Admin/AdminHome';
import AgentForm from './views/pages/Admin/components/AgentForm/AgentForm';
import AdOverview from './views/pages/AdOverview/AdOverview';
import Authorization from './views/pages/Authorization/Authorization';
import CandidatePage from './views/pages/CandidatePage/CandidatePage';
import CreateCandidate from './views/pages/CreateCandidate/CreateCandidate';
import Dashboard from './views/pages/Dashboard/Dashboard';
import EnrichingForm from './views/pages/EnrichingForm/EnrichingForm';

export const useStyles = makeStyles(() =>
  createStyles({
    loaderContainer: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

const App: React.FC = () => {
  const { isAuthenticated, isLoading, user,  getAccessTokenSilently } = useAuth0();
  const classes = useStyles();

  const dispatch = useDispatch();

  const locale = useAppSelector((state) => state.ui.locale);
  const [userAuth, toggleUserAuth] = useState(false)
  const [tokenLoading, setTokenLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        if (isAuthenticated) {
          toggleUserAuth(isAuthenticated)
        }
      } catch (e) {
        toggleUserAuth(isAuthenticated)
      } finally {
        setTokenLoading(false);
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated]);

  useEffect(() => {
    const storageLocale = localStorage.getItem('locale');
    if (storageLocale) {
      dispatch(setLocale(storageLocale as Locale));
    } else {
      localStorage.setItem('locale', Locale.NL);
    }
  }, [dispatch, userAuth]);

 return (

  <IntlContainer lang={locale}>
    {isLoading || tokenLoading ? <>
      <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
    </> : <>
     {isAuthenticated ? (
       <>
       <AppHeader />
          <Snackbar />
          <Container maxWidth="lg">
            <Switch>
              <Route exact path="/">
                <AdOverview />
              </Route>
              <Route exact path="/dashboard/:id">
                <Dashboard board />
              </Route>
              <Route exact path="/dashboard/:id/candidates">
                <Dashboard board />
              </Route>
              <Route exact path="/dashboard/:id/messages/">
                <Dashboard board={false} />
              </Route>
              <Route exact path="/dashboard/:id/:candidateId">
                <CandidatePage />
              </Route>
              <Route exact path="/dashboard/:id/messages/:candidateId">
                <CandidatePage />
              </Route>
            </Switch>
            <Route exact path="/enriching-form/:id">
              <EnrichingForm />
            </Route>
            <Route exact path="/create-candidate/:id">
              <CreateCandidate />
            </Route>
            <Route exact path="/admin">
             <AdminHome />
            </Route>
            <Route exact path="/admin/agents/:id">
             <AgentForm />
            </Route>
          </Container>
       </>
     ) : (
      <Switch>
      <Route exact path="/enriching-form/:id">
        <EnrichingForm />
      </Route>
      <Route path="*">
        <Authorization />
      </Route>
    </Switch>
     )}
     </>}
    
   </IntlContainer>
 )

};

export default App;
