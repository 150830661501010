

export interface UserState {
 token: string
}

export interface TokenString {
  token: string
}


export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';


export interface RemoveToken {
  type: typeof REMOVE_TOKEN;
}

export interface SetToken {
  type: typeof SET_TOKEN;
  payload: TokenString;
}

export type UserActions =  RemoveToken | SetToken;