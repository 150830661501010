import { Button, Popover } from '@material-ui/core';
import { KeyboardArrowUpOutlined } from '@material-ui/icons';
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import React, { useState } from 'react';

import { ExtraInfoModal } from '../../../Dashboard/components/ExtraInfoModal/ExtraInfoModal';
import { useStyles } from './styles';

type Props = {
  id: string;
  openEditForm: () => void;
};

export const Actions: React.FC<Props> = ({ id, openEditForm }) => {
  const classes = useStyles();
  const [open, setOpen] = useState<HTMLButtonElement | null>(null);
  const [extraInfoOpen, setExtraInfoOpen] = useState(false);

  const toggleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      setOpen(null);
      return;
    }
    setOpen(event.currentTarget);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={toggleOpen}
        endIcon={
          open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />
        }>
        Acties
      </Button>
      <Popover
        id={id}
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className={classes.root}>
          <Button variant="text" onClick={openEditForm}>
            Bewerk kandidaat
          </Button>
          <Button
            onClick={() => {
              setOpen(null);
              setExtraInfoOpen(true);
            }}
            variant="text">
            Extra informatie opvragen
          </Button>
        </div>
      </Popover>
      <ExtraInfoModal
        isOpen={extraInfoOpen}
        candidatesIds={[id]}
        handleClose={() => {
          setOpen(null);
          setExtraInfoOpen(false);
        }}
      />
    </>
  );
};
