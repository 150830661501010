import { AxiosResponse } from 'axios';

import { AdsFormValues, ListingsResponse } from '../types/ads';
import { CandidateFilterUrlParams, Lead } from '../types/candidates';
import { request, requestJsonLp, urlWithParam } from '../utils/request';

export const fetchAds = async (
  token: string,
  values: AdsFormValues,
): Promise<ListingsResponse> => {
  const response = await requestJsonLp(token).get('/lead_listings', {
    params: {
      ...values,
    },
  });
  return response.data;
};

export const fetchAdDetails = async (
  token: string,
  listingId: string,
): Promise<AxiosResponse<Lead>> => {
  const { data } = await request(token).get(`/lead_listings/${listingId}`);
  return data;
};
