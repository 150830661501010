import {
  AdsActions,
  GET_ADS_DATA,
  GET_ADS_DATA_ERROR,
  GET_ADS_DATA_LOADING,
  ListingErrorResponse,
  ListingsResponse,
} from '../types/ads';

export const RECIEVE_ADS_DATA = 'RECIEVE_ADS_DATA';

export const getAdsDataLoading = (): AdsActions => {
  return {
    type: GET_ADS_DATA_LOADING,
  };
};

export const getAdsData = (payload: ListingsResponse): AdsActions => {
  return {
    type: GET_ADS_DATA,
    payload,
  };
};

export const getAdsDataError = (payload: ListingErrorResponse): AdsActions => {
  return {
    type: GET_ADS_DATA_ERROR,
    payload,
  };
};
