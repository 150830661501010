import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { MuiTheme } from '../config/MuiTheme';

const MaterialContainer = ({ children }: Props): ReactElement => (
  <MuiThemeProvider theme={MuiTheme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

interface Props {
  children: React.ReactNode;
}

export default MaterialContainer;
