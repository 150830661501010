import { useAuth0 } from '@auth0/auth0-react';
import { Button, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../store/store';
import { sendMessageThunk } from '../../../../../store/thunks/candidatesThunk';
import { Candidate, Message } from '../../../../../store/types/candidates';
import { formatDate } from '../../../../utils/formatDate';
import { useStyles } from './styles';

type Props = {
  candidate: any;
};

export const Messages: React.FC<Props> = ({ candidate }) => {
  const classes = useStyles();
  const { user } = useAuth0();
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isModalOpen, toggleModalOpen] = useState(false);

  const dispatch = useDispatch();
  const {token} = useAppSelector((state) => state.user)

  const sendMessage = () => {
    dispatch(
      sendMessageThunk(token, {
        to: [candidate.id],
        message: { subject, body },
      }),
    );
  };

  const { messageSendingSuccess } = useAppSelector((state) => state.candidates);

  useEffect(() => {
    if (candidate.messages.length) {
      setSubject(candidate.messages[0].details.subject);
    }
  }, [candidate.messages]);

  useEffect(() => {
    if (messageSendingSuccess) {
      setSubject('');
      setBody('');
    }
  }, [messageSendingSuccess]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    handler: (value: string) => void,
  ) => {
    handler(event.target.value);
  };

  const handleReply = (value: string) => {
    setSubject(value);
  };

  return (
    <div>
      <Typography className={classes.messagesTitle} variant="h5">
        Berichten
      </Typography>
      <div className={classes.createMessageBlock}>
        <Typography variant="body1">
          <span className={classes.fromTo}>Aan:</span> {candidate.firstName}{' '}
          {candidate.lastName} ({candidate.email})
        </Typography>
        <Typography variant="body1">
          <span className={classes.fromTo}>Van:</span> {user?.name} (
          {user?.email})
        </Typography>
        <TextField
          id="outlined-textarea"
          placeholder="Onderwerpregel"
          variant="outlined"
          className={classes.messageInput}
          value={subject}
          onChange={(e) => handleInputChange(e, setSubject)}
        />
        <TextField
          id="outlined-multiline-static"
          placeholder="Bericht schrijven.."
          multiline
          rows={4}
          variant="outlined"
          className={classes.messageInput}
          value={body}
          onChange={(e) => handleInputChange(e, setBody)}
        />
        <Button
          onClick={sendMessage}
          className={`${classes.sendBtn} ${classes.replyBtn}`}
          disabled={!subject && !body}
          key={`${subject}`}
          variant="contained">
          Verstuur bericht
        </Button>
      </div>
      {candidate.messages &&
        candidate.messages.map((el: any) => (
          <div className={classes.mailContainer} key={el.id}>
            <Typography className={classes.mailTitle} variant="subtitle1">
              {el.details.subject}
            </Typography>
            <Typography
              component="p"
              className={classes.mailCaption}
              variant="caption">
              {formatDate(el.created_at, {
                day: 'numeric',
                month: '2-digit',
                year: '2-digit',
                minute: '2-digit',
                hour: '2-digit',
              })}{' '}
              - {el.from}
            </Typography>
            <Typography variant="body1">{el.details.body}</Typography>
            <div className={classes.replyBtnContainer}>
              <Button
                onClick={() => handleReply(el.details.subject)}
                variant="contained"
                className={classes.replyBtn}>
                Reageer
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
};
