import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#E5E5E5',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '89px 44px 47px',
      maxWidth: 500,
    },
    title: {
      fontSize: 32,
      textAlign: 'center',
      fontWeight: 600,
      margin: '51px 0 25px',
    },
  }),
);
