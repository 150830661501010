import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: '25px 42px',
      backgroundColor: '#F0F0F0',
      maxWidth: 454,
    },
    title: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 21,
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& > button': {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100&',
        borderRadius: '10%/50%',
        fontSize: 18,
        marginBottom: 10,
        backgroundColor: '#FFF',
        color: '#000',
        borderColor: '#000',
      },
    },
    icon: {
      margin: '0 8px 0 12px',
    },
  }),
);
