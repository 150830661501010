import { request } from '../utils/request';

export const sendCandidateNote = async (
  token: string,
  candidateId: string,
  value: string,
): Promise<any> => {
  const { data } = await request(token).post(`lead_notes`, {
    note: value,
    candidate: `/api/candidates/${candidateId}`,
  });
  return data;
};
