import { Reducer } from 'redux';

import {
  AdsActions,
  AdsState,
  GET_ADS_DATA,
  GET_ADS_DATA_ERROR,
  GET_ADS_DATA_LOADING,
} from '../types/ads';

const initialState: AdsState = {
  data: {
    'hydra:member': [],
    'hydra:totalItems': 0
  },
  isLoading: false,
  error: false,
  errorMessage: '',
  code: 200,
};

const adsReducer: Reducer<AdsState, AdsActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_ADS_DATA_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_ADS_DATA: {
      return {
        ...state,
        data: {
          'hydra:member': action.payload['hydra:member'],
          'hydra:totalItems': action.payload['hydra:totalItems']
        },
        isLoading: false,
      };
    }
    case GET_ADS_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        code: action.payload.code,
      };
    }
    default:
      return state;
  }
};

export default adsReducer;
