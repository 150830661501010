import { AxiosResponse } from 'axios';

import { AgentsItem } from '../../types/entityTypes';
import { request, requestJsonLp } from '../utils/request';

export const fetchAgents = async (
  token: string,
): Promise<any> => {
  const response = await requestJsonLp(token).get('/agents'
  );
  return response.data;
};

export const fetchAgentDetails = async (
  token: string,
  agentId: string,
): Promise<any> => {
  const response = await request(token).get(`/agents/${agentId}`);
  return response;
};
