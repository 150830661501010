import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 70,
    },
    title: {
      marginRight: 100,
      fontSize: 20,
      fontWeight: 'normal',
      color: '#000',
    },
    toolbar: {
      backgroundColor: '#D3D3D3',
    },
    iconButton: {
      color: '#000',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    popover: {
      padding: 16,
    },
    menuButton: {
      fontSize: 18,
      fontWeight: 'normal',
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
