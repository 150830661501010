import { MessagesStatus } from '../../types/entityTypes';
import { request } from '../utils/request';

export const setMessageStatus = async (
  token: string,
  ids: Array<string>,
  status: MessagesStatus,
): Promise<any> => {
  await request(token).post(`/messages/${status}`, ids);
};
