import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAdsData } from '../../../../../store/actions/adsActions';
import { useAppSelector } from '../../../../../store/store';
import { editAutomaticEnriching } from '../../../../../store/thunks/adsThunk';
import {
  getCandidatesThunk,
  requestExtraInfoThunk,
} from '../../../../../store/thunks/candidatesThunk';
import { Lead } from '../../../../../store/types/candidates';
import { useStyles } from './styles';

type Props = {
  candidatesIds: Array<string>;
  isOpen: boolean;
  handleClose: () => void;
  leadId?: string | null;
  leadData?: Lead;
};

export const ExtraInfoModal: React.FC<Props> = ({
  candidatesIds,
  isOpen,
  handleClose,
  leadId,
  leadData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { token } = useAppSelector((state) => state.user);
  const [enrichmentType, toggleEnrichmentType] = useState('manual')

  const handleRequest = () => {
    if(enrichmentType === 'instant'){
      dispatch(
        requestExtraInfoThunk(token, {
          agentEmail: user?.email as string,
          candidatesIds,
        }));
        if (leadId) { 
          dispatch(editAutomaticEnriching(token, 'instant', [leadId]))
          setTimeout(() => {
            handleClose()
            dispatch(getCandidatesThunk(token, leadId));
          }, 1000);
        }
    }else{
      dispatch(
        requestExtraInfoThunk(token, {
          agentEmail: user?.email as string,
          candidatesIds,
        }),
      );
      if (leadId) {
        setTimeout(() => {
          handleClose()
          dispatch(getCandidatesThunk(token, leadId));
        }, 1000);
      }
    }
  };

  const handleCheckBoxToggle = () => {
    switch(enrichmentType){
      case 'manual': return toggleEnrichmentType('instant')
      case 'instant': return toggleEnrichmentType('manual')
      default: return null
    }
  }

  useEffect(() => {
  
  }, [enrichmentType, dispatch])
  
  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onBackdropClick={handleClose}
      disableAutoFocus
      BackdropProps={{ style: { opacity: 0 } }}>
      <Fade in={isOpen}>
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.title}>
            Extra informatie opvragen
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Kandidaten vragen om extra informatie aan te leveren?
          </Typography>
          <Typography variant="body1">
            De kandidaten ontvangen een e-mail met daarin een link naar een
            formulier waar ze extra informatie aan kunnen leveren.
          </Typography>
          {leadData?.details.enrichment === 'manual' && (
            <div className={classes.checkboxContainer}>
              <FormControlLabel
                control={<Checkbox checked={enrichmentType === 'instant'} onChange={() => handleCheckBoxToggle()}/>}
                label={
                  !leadData
                    ? 'Automatisch formulieren versturen naar nieuwe kandidaten voor dit adres.'
                    : `Automatisch formulieren versturen naar nieuwe kandidaten voor ${leadData.street}  ${leadData.houseNumber}`
                }
              />
            </div>
          )}
          <div className={classes.buttonContainer}>
            <Button variant="text" onClick={handleClose}>
              Annuleren
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRequest}>
              Formulier versturen
            </Button>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};
