import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    tableHead: {
      backgroundColor: '#E7E7E7',
    },
    theadTitle: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    tableTitle: {
      marginBottom: 35,
      fontSize: 32,
      fontWeight: 'bold',
    },
    addressTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    chipListing:{
      textDecoration: 'none',
      marginLeft: 14,
      fontSize: 16
    },
    subCell: {
      width: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      '& :first-child': {
        marginRight: 8,
      },
      '& > p': {
        width: '50%',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    tableClickable: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);
