import React from 'react';
import { IntlProvider } from 'react-intl';

import AppLocale, { Locale } from '../config/I18n';

interface Props {
  lang: Locale;
}

const IntlContainer: React.FC<Props> = ({ children, lang }) => {
  return (
    <IntlProvider locale={lang} messages={AppLocale[lang]}>
      {children}
    </IntlProvider>
  );
};

export default IntlContainer;
