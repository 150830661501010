import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Fade,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../store/store';
import {
  createNoteThunk,
  deleteNoteThunk,
} from '../../../../../store/thunks/candidatesThunk';
import { formatDate } from '../../../../utils/formatDate';
import { useStyles } from './styles';
import { validationSchema } from './validation';

type Props = {
  notes: Array<any>;
};

export const Notes: React.FC<Props> = ({ notes }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { candidateId } = useParams() as {
    candidateId: string;
  };
  const {token} = useAppSelector((state) => state.user)

  const handleSubmit = ( values: { note: string }) => {
    dispatch(createNoteThunk(token, { candidateId, value: values.note }));
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setModalOpen(false);
  };

  const handleDelete = (id: string) => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(deleteNoteThunk(accessToken,{ id, candidateId }));
      } catch (e: any) {
        console.log(e.message);
      }
    };
    getUserToken();
   
  };

  return (
    <div>
      <div className={classes.notesHeader}>
        <Typography className={classes.notesTitle} variant="h5">
          Notities
        </Typography>
        <Button
          onClick={() => setOpen(true)}
          variant="outlined"
          startIcon={<AddIcon />}>
          Notitie
        </Button>
      </div>
      {open && (
        <form
          className={classes.newNoteContainer}
          onSubmit={formik.handleSubmit}>
          <div className={classes.noteInputContainer}>
            <TextField
              error={!!formik.errors.note}
              name="note"
              onChange={formik.handleChange}
              value={formik.values.note}
              placeholder="Notitie maken"
              multiline
              rows={3}
              variant="standard"
              className={classes.noteInput}
            />
            {formik.errors.note && (
              <Typography
                variant="caption"
                component="p"
                className={classes.error}>
                {formik.errors.note}
              </Typography>
            )}
          </div>
          <div className={classes.noteBtnContainer}>
            <Button variant="outlined" onClick={() => setModalOpen(true)}>
              Annuleren
            </Button>
            <Button variant="contained" type="submit">
              Notitie opslaan
            </Button>
          </div>
        </form>
      )}
      {notes?.map((note) => (
        <Paper className={classes.notePaper} key={note.id}>
          <Typography className={classes.noteBody} variant="body1">
            {note.note}
          </Typography>
          <div>
            <Typography
              className={classes.noteTime}
              component="span"
              variant="caption">
              {formatDate(note.created_at, {
                month: 'long',
                day: 'numeric',
              })}{' '}
              om{' '}
              {formatDate(note.created_at, {
                minute: 'numeric',
                hour: 'numeric',
              })}
            </Typography>
            <Button
              onClick={() => handleDelete(note.id)}
              className={classes.deleteNoteBtn}
              variant="text"
              startIcon={<DeleteIcon />}>
              Verwijderen
            </Button>
          </div>
        </Paper>
      ))}
      <Modal
        className={classes.modal}
        open={modalOpen}
        onBackdropClick={handleClose}
        disableAutoFocus
        BackdropProps={{ style: { opacity: 0 } }}>
        <Fade in={modalOpen}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle1" className={classes.title}>
              Weet je zeker dat je de notitie niet wilt opslaan?
            </Typography>
            <div className={classes.buttonContainer}>
              <Button variant="text" onClick={() => setModalOpen(false)}>
                Nee
              </Button>
              <Button variant="text" onClick={handleClose}>
                Ja
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};
