import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import {
  Employment,
  Gender,
  Guarantor,
  Household,
  Income,
  RentalPeriod,
} from '../../../store/types/candidates';
import { useStyles } from './styles';

const amountArray: Array<string> = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 10; i++) {
  amountArray.push(`${i}`);
}
amountArray.push('9+');

interface Props {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  errors: { [field: string]: string };
  values: { [field: string]: string | boolean | null | number };
  isValid: boolean;
  creation: boolean;
  handleCloseForm: () => void;
  agentDetails?: any;
}

const CandidateForm: React.FC<Props> = ({
  handleSubmit,
  handleChange,
  handleCloseForm,
  values,
  errors,
  isValid,
  creation,
  agentDetails,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const history = useHistory();

  const { id } = useParams() as {
    id: string;
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleRedirect = () => {
    if (handleCloseForm) {
      handleCloseForm();
      return;
    }
    history.push(`/dashboard/${id}`);
  };

  useEffect(() => {}, [values]);

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography variant="subtitle1" className={classes.subtitle}>
        <FormattedMessage id="enriching.form.profile" />
      </Typography>
      <TextField
        name="firstName"
        onChange={handleChange}
        value={values.firstName}
        placeholder={intl.formatMessage({ id: 'enriching.form.firstName' })}
      />
      <TextField
        name="lastName"
        onChange={handleChange}
        value={values.lastName}
        placeholder={intl.formatMessage({ id: 'enriching.form.lastName' })}
      />
      <TextField
        name="birthday"
        value={`${values.birthday}`}
        label={intl.formatMessage({ id: 'enriching.form.birthday' })}
        type="date"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl>
        <InputLabel>
          <FormattedMessage id="enriching.form.gender" />
        </InputLabel>
        <Select
          labelId="gender"
          name="gender"
          value={`${values.gender}`}
          onChange={handleChange}>
          {Object.keys(Gender).map((el) => (
            <MenuItem key={el} value={el}>
              <FormattedMessage id={`enriching.form.gender.${el}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="subtitle1" className={classes.subtitle}>
        <FormattedMessage id="enriching.form.contact" />
      </Typography>
      <TextField
        name="email"
        type="email"
        onChange={handleChange}
        value={values.email}
        placeholder={intl.formatMessage({ id: 'enriching.form.email' })}
      />
      <TextField
        name="phone"
        type="tel"
        onChange={handleChange}
        value={`${values.phone}`}
        placeholder={intl.formatMessage({ id: 'enriching.form.phone' })}
      />

      <Typography variant="subtitle1" className={classes.subtitle}>
        <FormattedMessage id="enriching.form.additional" />
      </Typography>
      <FormControl>
        <InputLabel>
          <FormattedMessage id="enriching.form.employment" />
        </InputLabel>
        <Select
          labelId="employment"
          name="employment"
          value={`${values.employment}`}
          onChange={handleChange}>
          {Object.keys(Employment).map((el) => (
            <MenuItem key={el} value={el}>
              <FormattedMessage id={`enriching.form.employment.${el}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>
          <FormattedMessage id="enriching.form.income" />
        </InputLabel>
        <Select
          name="income"
          value={`${values.income}`}
          onChange={handleChange}>
          {Object.values(Income).map((el) => (
            <MenuItem key={el.placeholder} value={el.value}>
              {el.placeholder}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>
          <FormattedMessage id="enriching.form.guarantor" />
        </InputLabel>
        <Select
          name="guarantor"
          value={`${values.guarantor}`}
          onChange={handleChange}>
          {Object.keys(Guarantor).map((el) => (
            <MenuItem key={el} value={el}>
              <FormattedMessage id={`enriching.form.guarantor.${el}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>
          <FormattedMessage id="enriching.form.household" />
        </InputLabel>
        <Select
          name="householdType"
          value={`${values.householdType}`}
          onChange={handleChange}>
          {Object.keys(Household).map((el) => (
            <MenuItem key={el} value={el}>
              <FormattedMessage id={`enriching.form.household.${el}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel>
          <FormattedMessage id="enriching.form.members" />
        </InputLabel>
        <Select
          name="household"
          value={`${values.household}`}
          onChange={handleChange}>
          {amountArray.map((el) => (
            <MenuItem key={el} value={el}>
              {el}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="subtitle1" className={classes.subtitle}>
        <FormattedMessage id="enriching.form.pet" />
      </Typography>
      <RadioGroup
        name="pets"
        value={`${values.pets}`}
        onChange={handleChange}
        row>
        <FormControlLabel
          value="true"
          control={<Radio checked={values.pets === 'true'} />}
          label={<FormattedMessage id="enriching.form.yes" />}
        />
        <FormControlLabel
          value="false"
          control={<Radio checked={values.pets === 'false'} />}
          label={<FormattedMessage id="enriching.form.no" />}
        />
      </RadioGroup>

      <Typography variant="subtitle1" className={classes.subtitle}>
        <FormattedMessage id="enriching.form.period" />
      </Typography>
      <div className={classes.rentalPeriodContainer}>
        <TextField
          name="availableSince"
          value={values.availableSince}
          label={intl.formatMessage({ id: 'enriching.form.startDate' })}
          type="date"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.startDate}
        />
        <FormControl className={classes.rentalPeriod}>
          <InputLabel>
            <FormattedMessage id="enriching.form.period" />
          </InputLabel>
          <Select name="term" value={`${values.term}`} onChange={handleChange}>
            {Object.keys(RentalPeriod).map((el) => {
              const obj = RentalPeriod[el as keyof typeof RentalPeriod];
              return (
                <MenuItem key={obj.placeholder} value={obj.value}>
                  <FormattedMessage id={`enriching.form.rentalPeriod.${el}`} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      {agentDetails && (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!values.info}
                onChange={handleChange}
                name="info"
              />
            }
            label={intl.formatMessage(
              { id: 'enriching.form.info' },
              {
                agent: agentDetails ? agentDetails.name : '',
              },
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!values.terms}
                onChange={handleChange}
                name="terms"
              />
            }
            label={intl.formatMessage({ id: 'enriching.form.terms' })}
          />
          <a
            href={agentDetails ? `${agentDetails.privacyPolicy}` : '#'}
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
        </FormGroup>
      )}
      {!creation ? (
        <Button
          type="submit"
          disabled={!isValid}
          key={`${isValid}`}
          variant="contained"
          className={classes.submitBtn}>
          <FormattedMessage id="enriching.form.submit" />
        </Button>
      ) : (
        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={() => setConfirmModalOpen(true)}>
            Annuleren
          </Button>
          <Button className={classes.saveBtn} type="submit" variant="contained">
            Opslaan
          </Button>
        </div>
      )}
      {(errors.firstName || errors.lastName || errors.email) && (
        <Typography variant="body1" className={classes.error}>
          <FormattedMessage id="enriching.form.error.fields" />
        </Typography>
      )}
      {(errors.terms || errors.info) && (
        <Typography variant="body1" className={classes.error}>
          <FormattedMessage id="enriching.form.error.checkboxes" />
        </Typography>
      )}
      {!creation && (
        <Typography variant="caption" className={classes.disclamer}>
          <>
            <LockIcon fontSize="small" />{' '}
            <FormattedMessage id="enriching.form.disclamer" />
          </>
        </Typography>
      )}
      <Modal
        className={classes.modal}
        open={confirmModalOpen}
        onBackdropClick={() => setConfirmModalOpen(false)}
        disableAutoFocus
        BackdropProps={{ style: { opacity: 0 } }}>
        <Fade in={confirmModalOpen}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle1" className={classes.title}>
              Annuleren
            </Typography>
            <Typography variant="body1">
              Weet je zeker dat je de aanpassingen niet wilt opslaan?
            </Typography>
            <div className={classes.buttonContainer}>
              <Button variant="text" onClick={() => setConfirmModalOpen(false)}>
                Nee
              </Button>
              <Button variant="text" onClick={handleRedirect}>
                Ja
              </Button>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </form>
  );
};

export default CandidateForm;
