import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: 94,
    },
    languageSwitch: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    activeBtn: {
      fontWeight: 'bold',
    },
    inactiveBtn: {
      textDecoration: 'underline',
    },
    logo: {
      width: 140,
      height: 100,
      backgroundColor: '#E5E5E500',
      marginRight: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    agentContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 32,
    },
    title: {
      fontSize: 32,
      fontWeight: 600,
      marginBottom: 45,
    },
    formContainer: {
      padding: '0 51px 31px 51px',
    },
  }),
);
