import { ListingsResponse } from '../types/ads';
import { request } from '../utils/request';

export const toggleAutoEnriching = async (
  token: string,
  enrichingMode: string,
  listingsIds: Array<string>,
): Promise<ListingsResponse> => {
  const response = await request(token).put(
    `/lead_listings/enriching/${enrichingMode}`,
    {
      listingIds: listingsIds,
    },
  );
  return response.data;
};
