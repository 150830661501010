import { useAuth0 } from '@auth0/auth0-react';
import {
    Button,
    Checkbox,
    Container,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
  } from '@material-ui/core';
  import Typography from '@material-ui/core/Typography';
  import LockIcon from '@material-ui/icons/Lock';
import { useFormik } from 'formik';
  import React, { useEffect, useState } from 'react';
  import { FormattedMessage, useIntl } from 'react-intl';
  import { useDispatch } from 'react-redux';
  import { useHistory, useParams } from 'react-router-dom';

import { setUserToken } from '../../../../../store/actions/userActions';
import { useAppSelector } from '../../../../../store/store';
import { createAgent, deleteAgentLogins, getAgentsConfiguration, saveAgentLogins, updateAgent } from '../../../../../store/thunks/agentsThunk';
import { REMOVE_EMAIL_INQUIRY, REMOVE_LEADS_ID, REMOVE_LOGIN, SET_EMAIL_INQUIRY, SET_LEADS_ID, SET_LOGIN } from '../../../../../store/types/agents';
import AdminLoginsForm from './AdminLoginsForm';
  import { useStyles } from './styles';

  const amountArray: Array<string> = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 10; i++) {
    amountArray.push(`${i}`);
  }
  amountArray.push('9+');

  interface Props {
    agentDetails?: any;
  }

  const AgentForm: React.FC<Props> = ({
    agentDetails,
  }) => {

    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const { token } = useAppSelector((state) => state.user);
    const { selectedAgent } = useAppSelector((state) => state.agents);
    const { id } = useParams() as {
      id: string;
    };
    const agentInfo = selectedAgent.details
    const arrUsers : Array<string> = [];
    const arrEmailsInq : Array<string> = agentInfo.emailsForInquiries;
    const [admin, setAdmin] = useState('')
    const [email, setEmail] = useState('')
    const [leadId, setLeadId] = useState('')
    const [showHouseNumber, toggleShowHouseNumber] = useState(selectedAgent.details.show_house_number)
    const [isEmailSecure, toggleIsEmailSecure] = useState(selectedAgent.details.isEmailSecure)
    const {emailsForInquiries, leadsAgentIds} = agentInfo;
    const users = selectedAgent.logins
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);


    const formik = useFormik({
      initialValues: {
        name: agentInfo.name,
        defaultTemplateId: agentInfo.emailTemplateId,
        rejectionTemplateId: agentInfo.rejectionTemplateId,
        defaultPhone:  agentInfo.defaultPhone || "",
        street: agentInfo.street,
        housenumber: agentInfo.house,
        parariusOfficeApiKey: selectedAgent?.parariusOfficeKey || "",
        privacyUrl: agentInfo.privacyPolicy || "",
        city: agentInfo.city,
        logoUrl: agentInfo.logo,
        websiteUrl: agentInfo.url,
        postalCode: agentInfo.postalCode,
        defaultEmail: agentInfo.defaultEmail || "",
        enrichingTemplateId: agentInfo.enrichmentTemplateId,
        parariusId: agentInfo.parariusId,
        show_house_number: agentInfo.show_house_number,
        isEmailSecure: agentInfo.isEmailSecure
      },
      enableReinitialize: true,
      onSubmit: ({ ...values }) => {
        const updatedValues = {
          ...values
        };
      },
    });

    const setNewAdminValue = (email: string) => {
      setAdmin(email)
    }

    const createAdmin = (email: string) => {
    dispatch(saveAgentLogins(token, id , email))
    setAdmin('');
    }

    const createEmail = (email: string) => {
      dispatch({
        type: SET_EMAIL_INQUIRY,
        payload: email
      })
      setEmail('');
    }

    const deleteEmail = (email: string) => {
      dispatch({
        type: REMOVE_EMAIL_INQUIRY,
        payload: email
      })
    }

    const createLeadId = (id: string) => {
      dispatch({
        type: SET_LEADS_ID,
        payload: id
      })
      setLeadId('');
    }

    const deleteLeadId = (id: string) => {
      dispatch({
        type: REMOVE_LEADS_ID,
        payload: id
      })
    }
    const removeAdmin = (id: number) => {
      dispatch(deleteAgentLogins(token, id))
  }

    const handleSubmitAgent = () => {
      const data = {
        details: {
          city: formik.values.city,
          street: formik.values.street,
          house: formik.values.housenumber,
          logo: formik.values.logoUrl,
          defaultEmail: formik.values.defaultEmail,
          defaultPhone: formik.values.defaultPhone,
          emailsForInquiries,
          leadsAgentIds,
          rejectionTemplateId: formik.values.rejectionTemplateId,
          enrichmentTemplateId: formik.values.enrichingTemplateId,
          postalCode: formik.values.postalCode,
          name: formik.values.name,
          privacyPolicy: formik.values.privacyUrl,
          url: formik.values.websiteUrl,
          emailTemplateId: formik.values.defaultTemplateId,
          parariusId: formik.values.parariusId,
          show_house_number: formik.values.show_house_number,
          isEmailSecure: formik.values.isEmailSecure,
        },
        name: formik.values.name,
        parariusOfficeKey: formik.values.parariusOfficeApiKey,
        logins: users
      }

      if(id === 'new'){
        dispatch(createAgent(token, data))
        handleRedirect()
      }else{
        dispatch(updateAgent(token, selectedAgent.id, data))
        handleRedirect()
      }

    }

    const handleRedirect = () => {
      history.push(`/admin`);
    };



    useEffect(() => {
      const getUserToken = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(setUserToken({ token: accessToken }))
          dispatch(getAgentsConfiguration(accessToken, id))

        } catch (e: any) {
          console.log(e.message);
        }
      };
    getUserToken()
    console.log(selectedAgent.details.show_house_number)
    }, [
        id,
        dispatch,
        getAccessTokenSilently,
        admin,
        showHouseNumber,
        isEmailSecure,
        selectedAgent.details.show_house_number,
        selectedAgent.details.isEmailSecure
    ]);

    return (
        <Container className={classes.container}>
            <Grid container spacing={2}>
        <Grid item xs={7}>
        <form className={classes.form}>
       <Typography className={classes.formSection}>
           Gegevens Kantoor
       </Typography>
        <TextField
        aria-placeholder='Naam kantoor'
          name="name"
          label="Naam Kantoor*"
          onChange={formik.handleChange}
          value={formik.values.name}
          defaultValue={agentInfo.name}
          className={classes.textInput}
          placeholder={agentInfo.name}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name="postalCode"
          onChange={formik.handleChange}
          value={formik.values.postalCode}
          className={classes.textInput}
          placeholder={agentInfo.postalCode}
          InputLabelProps={{
            shrink: true,
          }}
          label="Postcode kantoor"
        />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              name="street"
              onChange={formik.handleChange}
              value={formik.values.street}
              defaultValue={`${agentInfo.street}`}
              className={classes.textInput}
              placeholder={`${agentInfo.street}`}
              label="Straat kantoor"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="housenumber"
              type='text'
              onChange={formik.handleChange}
          value={formik.values.housenumber}
              defaultValue={agentInfo.house}
              className={classes.textInput}
              label="Huisnummer kantoor"
              placeholder={agentInfo.house}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <TextField
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              defaultValue={agentInfo.city}
              className={classes.textInput}
              placeholder={agentInfo.city}
              label="Vestigingsplaats kantoor"
              InputLabelProps={{
                shrink: true,
              }}
            />


         <TextField
          name="websiteUrl"
          onChange={formik.handleChange}
          value={formik.values.websiteUrl}
          defaultValue={agentInfo.url}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
          label="Website URL"
          placeholder={agentInfo.url}
        />
         <TextField
          name="defaultPhone"
          onChange={formik.handleChange}
          value={formik.values.defaultPhone}
          defaultValue={agentInfo.defaultPhone}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
          label="Telefoon"
          placeholder={agentInfo.defaultPhone}
        />
         <TextField
          name="privacyUrl"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.privacyUrl}
          defaultValue={agentInfo.privacyPolicy}
          placeholder={agentInfo.privacyPolicy}
          className={classes.textInput}
          label="Privacy beleid URL*"
          InputLabelProps={{
            shrink: true,
          }}
        />
    <div style={{marginTop: '1.5rem ', marginBottom:'1.5rem'}}>
          <p>Huisnummer tonen in verrijkingsformulier</p>
          <span style={{display: 'flex', flexDirection: 'row'}}>
          <Checkbox
          defaultChecked={formik.values.show_house_number}
          aria-label="Huisnummer tonen"
          checked={formik.values.show_house_number}
          name="show_house_number"
          onChange={formik.handleChange}/>
           <p>Tonen in formulier</p>
          </span>

          </div>
    <div style={{marginTop: '1.5rem ', marginBottom:'1.5rem'}}>
          <p>Configuratie e-mailafzender (verstuurt standaard via leadflow)</p>
          <span style={{display: 'flex', flexDirection: 'row'}}>
          <Checkbox
          defaultChecked={formik.values.isEmailSecure}
          aria-label="Configuratie e-mailafzender"
          checked={formik.values.isEmailSecure}
          name="isEmailSecure"
          onChange={formik.handleChange}/>
           <p>E-mail verzenden namens makelaar</p>
          </span>

          </div>

<div>
          <h3>IDs om leads op te halen*</h3>
          {leadsAgentIds.map((id, index) =>
          <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              name={`${id} ${index}`}
              disabled
              variant='standard'
              defaultValue={id}
              className={classes.textInput}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.removeEmailContainer}>
           <Button variant="outlined" onClick={() => deleteLeadId(id)}>Verwijder ID</Button>
          </Grid>
        </Grid>
        )}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={8}>
          <TextField
          name="leads_ids"
          value={leadId}
          placeholder={leadId}
          onChange={(e) => setLeadId(e.target.value)}
          className={classes.textInput}
          label="Leads ID"
          InputLabelProps={{
            shrink: true,
          }}
        />
          </Grid>
          <Grid item xs={4} className={classes.removeEmailContainer}>
           <Button variant="contained" onClick={()=> createLeadId(leadId) }>ID toevoegen</Button>
          </Grid>
        </Grid>
         <TextField
          name="parariusOfficeApiKey"
          onChange={formik.handleChange}
          value={formik.values.parariusOfficeApiKey}
          defaultValue={selectedAgent.parariusOfficeKey}
          placeholder={selectedAgent.parariusOfficeKey}
          className={classes.textInput}
          label="API key Pararius Office"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          name="parariusId"
          onChange={formik.handleChange}
          value={formik.values.parariusId}
          defaultValue={selectedAgent.details.parariusId}
          placeholder={selectedAgent.details.parariusId}
          className={classes.textInput}
          label="Treehouse/Pararius UUID"
          InputLabelProps={{
            shrink: true,
          }}
        />
         <TextField
          name="logoUrl"
          onChange={formik.handleChange}
          value={formik.values.logoUrl}
          defaultValue={agentInfo.logo}
          className={classes.textInput}
          placeholder={agentInfo.logo}
          label="Logo URL*"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div>
          <h3>Emails voor aanvragen*</h3>
          {emailsForInquiries.map((email, index) =>
          <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              name={`${email} ${index}`}
              disabled
              variant='standard'
              defaultValue={email}
              className={classes.textInput}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4} className={classes.removeEmailContainer}>
           <Button variant="outlined" onClick={() => deleteEmail(email)}>Verwijder email</Button>
          </Grid>
        </Grid>
        )}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={8}>
          <TextField
          name="email_for_inquiries"
          value={email}
          placeholder={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.textInput}
          label="E-mail voor aanvragen"
          InputLabelProps={{
            shrink: true,
          }}
        />
          </Grid>
          <Grid item xs={4} className={classes.removeEmailContainer}>
           <Button variant="contained" onClick={()=> createEmail(email) }>Email toevoegen</Button>
          </Grid>
        </Grid>


       <Typography className={classes.formSection}>
         E-mail verzenden
       </Typography>
       <TextField
          name="defaultEmail"
          value={formik.values.defaultEmail}
          onChange={formik.handleChange}
          defaultValue={agentInfo.defaultEmail}
          placeholder={agentInfo.defaultEmail}
          className={classes.textInput}
          label="Verzendadres e-mail*"
          InputLabelProps={{
            shrink: true,
          }}
        />
           <TextField
          name="defaultTemplateId"
          value={formik.values.defaultTemplateId}
          onChange={formik.handleChange}
          placeholder={`${agentInfo.emailTemplateId}`}
          defaultValue={agentInfo.emailTemplateId}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
          label="Standaard e-mail template ID*"
        />
         <TextField
          name="enrichingTemplateId"
          value={formik.values.enrichingTemplateId}
          onChange={formik.handleChange}
          placeholder={`${agentInfo.enrichmentTemplateId}`}
          defaultValue={agentInfo.enrichmentTemplateId}
          className={classes.textInput}
          InputLabelProps={{
            shrink: true,
          }}
          label="Verrijking e-mail template ID*"
        />
         <TextField
          name="rejectionTemplateId"
          value={formik.values.rejectionTemplateId}
          onChange={formik.handleChange}
          placeholder={`${agentInfo.rejectionTemplateId}`}
          defaultValue={agentInfo.rejectionTemplateId}
          className={classes.textInput}
          label="Afwijzing e-mail template ID*"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Typography className={classes.formSection}>
         Beheerders toevoegen
       </Typography>
      {users.map((admin) =>

         <AdminLoginsForm
         removeAdmin={removeAdmin}
         key={admin}
         uri={admin}
         />
      )}


        <Grid container spacing={2}>
          <Grid item xs={8}>
          <TextField
          name="admin_logins"
          onChange={(e) => setNewAdminValue(e.target.value)}
          value={admin}
          className={classes.textInput}
          label="E-mail van de beheerder"
          InputLabelProps={{
            shrink: true,
          }}
        />
          </Grid>
          <Grid item xs={4} className={classes.removeEmailContainer}>
           <Button variant="contained" onClick={()=> createAdmin(admin) }>Email toevoegen</Button>
          </Grid>
        </Grid>


        <Button variant="contained" className={classes.submitButton} onClick={() => handleSubmitAgent()}>Informatie opslaan</Button>
        <Modal
          open={confirmModalOpen}
          onBackdropClick={() => setConfirmModalOpen(false)}
          disableAutoFocus
          BackdropProps={{ style: { opacity: 0 } }}>
          <Fade in={confirmModalOpen}>
            <Paper >
              <Typography variant="subtitle1" >
                Annuleren
              </Typography>
              <Typography variant="body1">
                Weet je zeker dat je de aanpassingen niet wilt opslaan?
              </Typography>
              <div >
                <Button variant="text" onClick={() => setConfirmModalOpen(false)}>
                  Nee
                </Button>
                <Button variant="text" onClick={handleRedirect}>
                  Ja
                </Button>
              </div>
            </Paper>
          </Fade>
        </Modal>
      </form>
      </Grid>
      <Grid item xs={5}/>
      </Grid>
      </Container>
    );
  };

  export default AgentForm;
