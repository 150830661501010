import { CandidateFormValues } from '../types/candidates';
import { request } from '../utils/request';

export const postCandidateData = async (
  token: string,
  id: string,
  values: CandidateFormValues,
): Promise<any> => {
  await request(token).post(`/candidates`, {
    ...values,
    listing: `/api/lead_listings/${id}`,
    lead: `api/leads/${id}`
  });
};
