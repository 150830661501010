import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    tabLabel: {
      display: 'flex',
      alignItems: 'center',
    },
    tabLabelText: {
      textTransform: 'none',
    },
    icon: {
      marginRight: 10,
    },
  }),
);
