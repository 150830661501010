import { Container } from '@material-ui/core';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../store/store';
import { HeaderTab } from '../../../types/entityTypes';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import LeadHeader from '../../components/LeadHeader/LeadHeader';
import { MessagesTab } from '../../components/MessagesTab/MessagesTab';
import { CandidatesTab } from './components/CandidatesTab/CandidatesTab';

export interface TypeBoard {
  board: boolean;
}

const Dashboard: React.FC<TypeBoard> = (props: TypeBoard) => {
  const { data, isLoading } = useAppSelector(
    (state) => state.candidates.candidatesList,
  );
  const { headerTab } = useAppSelector((state) => state.ui);

  const { id } = useParams() as { id: string };

  return (
    <Container maxWidth="lg">
      {!isLoading && (
        <Breadcrumbs
          links={[
            {
              href: `/dashboard/${id}`,
              title: `${data.street} ${data.houseNumber}, ${data.city}`,
            },
            {
              title: props.board ? 'Kandidaten' : 'Berichten',
            },
          ]}
        />
      )}
      <LeadHeader data={data} hideTab />
      {headerTab === HeaderTab.CANDIDATE ? <CandidatesTab leadData={data}/> : <MessagesTab />}
    </Container>
  );
};

export default Dashboard;
