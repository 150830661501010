import { Reducer } from 'redux';

import {
  AgentActions,
  AgentsState,
  GET_AGENTS_DATA,
  GET_AGENTS_DATA_ERROR,
  GET_AGENTS_DATA_LOADING,
  GET_AGENTS_DETAILS_DATA,
  REMOVE_EMAIL_INQUIRY,
  REMOVE_LEADS_ID,
  REMOVE_LOGIN,
  SET_EMAIL_INQUIRY,
  SET_LEADS_ID,
  SET_LOGIN,
  SET_LOGINS_FROM_API,
} from '../types/agents';

const initialState: AgentsState = {
  data: {
    'hydra:member': [],
    'hydra:totalItems': 0,
  },
  selectedAgent: {
    '@id': '',
    '@type': '',
    createdAt: '',
    listings: [],
    id: '',
    logins: [],
    name: '',
    systemName: '',
    parariusOfficeKey: '',
    details: {
      parariusId: '',
      phone: '',
      name: '',
      logo: '',
      city: '',
      url: '',
      postalCode: '',
      house: '',
      street: '',
      defaultEmail: '',
      defaultPhone: '',
      emailsForInquiries: [],
      leadsAgentIds: [],
      parariusOfficeApiKey: '',
      rejectionTemplateId: '',
      emailTemplateId: '',
      enrichmentTemplateId: '',
      show_house_number: true,
      isEmailSecure: false,
      privacyPolicy: '',
    },
  },
  isLoading: false,
  error: false,
  errorMessage: '',
  code: 200,
};

const agentsReducer: Reducer<AgentsState, AgentActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_AGENTS_DATA_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_AGENTS_DATA: {
      return {
        ...state,
        data: {
          'hydra:member': action.payload['hydra:member'],
          'hydra:totalItems': action.payload['hydra:totalItems'],
        },
        isLoading: false,
      };
    }
    case GET_AGENTS_DETAILS_DATA: {
      return {
        ...state,
        selectedAgent: action.payload,
        isLoading: false,
      };
    }
    case GET_AGENTS_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        code: action.payload.code,
      };
    }
    case SET_EMAIL_INQUIRY: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          details: {
            ...state.selectedAgent.details,
            emailsForInquiries: [
              ...state.selectedAgent.details.emailsForInquiries,
              action.payload,
            ],
          },
        },
      };
    }
    case REMOVE_EMAIL_INQUIRY: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          details: {
            ...state.selectedAgent.details,
            emailsForInquiries:
              state.selectedAgent.details.emailsForInquiries.filter(
                (email) => email !== action.payload,
              ),
          },
        },
      };
    }
    case SET_LEADS_ID: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          details: {
            ...state.selectedAgent.details,
            leadsAgentIds: [
              ...state.selectedAgent.details.leadsAgentIds,
              action.payload,
            ],
          },
        },
      };
    }
    case REMOVE_LEADS_ID: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          details: {
            ...state.selectedAgent.details,
            leadsAgentIds:
              state.selectedAgent.details.leadsAgentIds.filter(
                (email) => email !== action.payload,
              ),
          },
        },
      };
    }
    case REMOVE_LOGIN: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          logins: [
            ...state.selectedAgent.logins.filter(
              (login: string) =>
                login !== `/api/agent_logins/${action.payload}`,
            ),
          ],
        },
      };
    }
    case SET_LOGIN: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          logins: [...state.selectedAgent.logins, action.payload],
        },
      };
    }
    case SET_LOGINS_FROM_API: {
      return {
        ...state,
        selectedAgent: {
          ...state.selectedAgent,
          logins: [...action.payload],
        },
      };
    }
    default:
      return state;
  }
};

export default agentsReducer;
