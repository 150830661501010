import { Locale } from '../../config/I18n';
import { HeaderTab } from '../../types/entityTypes';

export interface UIState {
  snackbar: {
    visible: boolean;
    type: SnackbarType;
    message: string;
  };
  locale: Locale;
  headerTab: HeaderTab;
  editCandidateOpen: boolean;
}

export enum SnackbarType {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_HEADER_TAB = 'SET_HEADER_TAB';
export const TOGGLE_PROFILE_EDIT = 'TOGGLE_PROFILE_EDIT';

export interface ShowSnackbar {
  type: typeof SHOW_SNACKBAR;
  payload: SnackbarPayload;
}
export interface CloseSnackbar {
  type: typeof CLOSE_SNACKBAR;
}

export interface SetLocale {
  type: typeof SET_LOCALE;
  payload: Locale;
}

export interface SetHeaderTab {
  type: typeof SET_HEADER_TAB;
  payload: HeaderTab;
}
export interface ToggleProfileEdit {
  type: typeof TOGGLE_PROFILE_EDIT;
  payload: boolean;
}

export type UIActions =
  | ShowSnackbar
  | CloseSnackbar
  | SetLocale
  | SetHeaderTab
  | ToggleProfileEdit;

export interface SnackbarPayload {
  message: string;
  type: SnackbarType;
}
