import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px',
    },
    form: {
      display: 'flex',
      marginBottom: 30,
    },
    formColumn: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 16px',
    },
    columnHeader: {
      fontWeight: 'bold',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      '& > button': {
        marginRight: 12,
      },
      '& :nth-child(3)': {
        marginRight: 0,
      },
    },
    refreshBtn: {
      marginRight: 'auto !important',
      textDecoration: 'underline',
    },
  }),
);
