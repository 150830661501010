export default {
  'enriching.title': 'Bedankt voor je interesse in {address} {houseNumber}',
  'enriching.body':
    'Wij leren je graag beter kennen om in te kunnen schatten of je een passende kandidaat bent voor de woning. Jouw gegevens worden uitsluitend met ons in een beveiligde omgeving gedeeld.{br} Op ieder moment kun je ons vragen je gegevens te verwijderen. ',
  'enriching.form.profile': 'Profiel',
  'enriching.form.firstName': 'Voornaam*',
  'enriching.form.lastName': 'Achternaam*',
  'enriching.form.contact': 'Contactgegevens',
  'enriching.form.birthday': 'Geboortedatum',
  'enriching.form.gender': 'Aanhef',
  'enriching.form.email': 'E-mailadres*',
  'enriching.form.phone': 'Telefoonnummer',
  'enriching.form.additional': 'Aanvullende kenmerken',
  'enriching.form.employment': 'Werksituatie',
  'enriching.form.income': 'Bruto maandinkomen huishouden',
  'enriching.form.guarantor': 'Garantsteller',
  'enriching.form.household': 'Samenstelling huishouden',
  'enriching.form.members': 'Aantal leden huishouden',
  'enriching.form.pet': 'Huisdier',
  'enriching.form.yes': 'Ja',
  'enriching.form.no': 'Nee',
  'enriching.form.period': 'Duur en start huurperiode',
  'enriching.form.startDate': 'Intrekdatum',
  'enriching.form.info':
    'Ik ben me ervan bewust dat de gegevens gedeeld worden met {agent}*',
  'enriching.form.terms': 'Ik ga akkoord met het privacybeleid*',
  'enriching.form.submit': 'Gegevens delen',
  'enriching.form.disclamer':
    'Gegevens worden opgeslagen in een beveiligde omgeving',
  'enriching.form.gender.MALE': 'Heer',
  'enriching.form.gender.FEMALE': 'Mevrouw',
  'enriching.form.gender.UNSET': 'Zeg ik liever niet',
  'enriching.form.employment.EMPLOYED_BY_EMPLOYER': 'Werkzaam bij werkgever',
  'enriching.form.employment.ENTREPRENEUR': 'Ondernemer of ZZP-er',
  'enriching.form.employment.STUDENT': 'Student',
  'enriching.form.employment.RETIRED': 'Gepensioneerd',
  'enriching.form.employment.UNEMPLOYED': 'Niet werkzaam',
  'enriching.form.guarantor.IN_NETHERLANDS':
    'Garantsteller woonachtig in Nederland',
  'enriching.form.guarantor.ABROAD':
    'Garantsteller woonachtig in het buitenland',
  'enriching.form.guarantor.NO_GUARANTOR': 'Geen garantsteller',
  'enriching.form.household.SINGLE': 'Alleenstaand ',
  'enriching.form.household.WITH_PARTNER': 'Samenwonend met partner',
  'enriching.form.household.WITH_FAMILY': 'Samenwonend met gezin',
  'enriching.form.household.WITH_FRIENDS': 'Samenwonend met vrienden',
  'enriching.form.rentalPeriod.ZERO_THREE': '0 - 3 maanden',
  'enriching.form.rentalPeriod.THREE_SIX': '3 - 6 maanden',
  'enriching.form.rentalPeriod.SIX_TWELVE': '6 - 12 maanden',
  'enriching.form.rentalPeriod.YEAR': '1 - 2 jaar',
  'enriching.form.rentalPeriod.TWO_YEARS': '2 jaar of langer',
  'enriching.form.rentalPeriod.UNDEFINED': 'Onbepaalde tijd',
  'enriching.form.rentalPeriod.INDEFINITE': 'Onbepaalde tijd',
  'enriching.form.error.fields': 'Vul de verplichte velden in',
  'enriching.form.error.checkboxes':
    'Bevestig dat je akkoord bent met onze voorwaarden',
    'adoverview.status.available': 'Beschikbaar',
    'adoverview.status.option': 'Onder optie',
    'adoverview.status.rented': 'Verhuurd'
};
