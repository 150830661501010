import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      marginTop: 12,
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    checkboxContainer: {
      padding: 4,
    },
    buttonContainer: {
      padding: '0 16px 0 16px',
      '& > button': {
        marginRight: 9,
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    grey: {
      backgroundColor: '#F6F6F6',
    },
    noCandidateMessage: {
      fontSize: 16,
      marginTop: 46,
    },
    activeBtn: {
      fontWeight: 'bold',
      boxShadow: '1px 1px 3px 1px rgba(0, 0, 0, 0.08)',
    },
  }),
);
