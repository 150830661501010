import {
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Search from '@material-ui/icons/Search';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import React from 'react';

import { CandidatesFilterTabsState } from '../../../../../types/entityTypes';
import { useStyles } from './styles';

type Props = {
  state: CandidatesFilterTabsState;
  handleChange: (
    event: React.ChangeEvent<{}>,
    newValue: CandidatesFilterTabsState,
  ) => void;
  allCount: number;
  likedCount: number;
  dislikedCount: number;
  canceledCount: number;
  toJudgeCount: number;
  assessCount: number
};

export const FilterTabs: React.FC<Props> = ({
  state,
  handleChange,
  allCount,
  likedCount,
  dislikedCount,
  canceledCount,
  toJudgeCount,
  assessCount
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
      <Tabs
        value={state}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto">
        <Tab
          value={CandidatesFilterTabsState.NONE}
          label={
            <div className={classes.tabLabel}>
              <Search className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Nieuw ({toJudgeCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={CandidatesFilterTabsState.ASSESSMENT}
          label={
            <div className={classes.tabLabel}>
              <Search className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Te beoordelen ({assessCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={CandidatesFilterTabsState.SUITABLE}
          label={
            <div className={classes.tabLabel}>
              <ThumbUpAltOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Geschikt ({likedCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={CandidatesFilterTabsState.NOT_SUITABLE}
          label={
            <div className={classes.tabLabel}>
              <ThumbDownAltOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Niet Geschikt ({dislikedCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={CandidatesFilterTabsState.REJECTED}
          label={
            <div className={classes.tabLabel}>
              <CloseOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Afgewezen ({canceledCount})
              </Typography>
            </div>
          }
        />
        <Tab
          value={CandidatesFilterTabsState.ALL}
          label={
            <div className={classes.tabLabel}>
              <AccountCircleOutlinedIcon className={classes.icon} />
              <Typography className={classes.tabLabelText} variant="body1">
                Alles ({allCount})
              </Typography>
            </div>
          }
        />
      </Tabs>
    </div>
  );
};
