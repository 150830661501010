import { CandidateStatus } from '../../types/entityTypes';

export const formatCandidateStatus = (str: string): string => {
  switch (str) {
    case CandidateStatus.none:
      return 'Te beoordelen';
    case CandidateStatus.suitable:
      return 'Geschikt';
    case CandidateStatus.not_suitable:
      return 'Niet geschikt';
    case CandidateStatus.rejected:
      return 'Afgewezen';

    default:
      return '';
  }
};
