import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import EuroIcon from '@material-ui/icons/Euro';
import PeopleIcon from '@material-ui/icons/People';
import PetsIcon from '@material-ui/icons/Pets';
import RemoveIcon from '@material-ui/icons/Remove';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../store/store';
import {
  changeCandidateStatusThunk,
  getCandidatesThunk,
} from '../../../../../store/thunks/candidatesThunk';
import {
  CandidateStatusTransitionNames,
  Lead,
} from '../../../../../store/types/candidates';
import { CandidateStatus, Filters } from '../../../../../types/entityTypes';
import { BulkActionsPopover } from '../BulkActionsPopover/BulkActionsPopover';
import { BulkMessages } from '../BulkMessages/BulkMessages';
import { ExtraInfoModal } from '../ExtraInfoModal/ExtraInfoModal';
import { SetStatusModal } from '../SetStatusModal/SetStatusModal';
import { StatusButton } from '../StatusButton/StatusButton';
import { CandidatesDataCell } from './components/CandidatesDataCell';
import { useStyles } from './styles';

type Props = {
  leadData?: Lead;
  candidatesArray: any;
  isLoading: boolean;
  sorting: string;
  applySorting: (key: string) => void;
  setSelectedRows: Function;
  selectedRows: Array<string>;
  page: number;
  itemsPerPage: number;
  handleChangeRowsPerPage: any;
  handleChangePage: any;
  count: number | any;
  filtersValues: Filters;
};

export const CandidatesTable: React.FC<Props> = ({
  candidatesArray,
  isLoading,
  sorting,
  applySorting,
  setSelectedRows,
  selectedRows,
  page,
  itemsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
  count,
  filtersValues,
  leadData,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const { id: leadId } = useParams() as { id: string };

  const [extraInformationOpen, setExtraInformationOpen] = useState(false);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [anchorMessages, setAnchorMessages] =
    useState<HTMLButtonElement | null>(null);
  const [bulkMenuOpen, setBulkMenuOpen] = useState<HTMLButtonElement | null>(
    null,
  );

  const handleSortingChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    applySorting(event.target.value as string);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = candidatesArray.map((n: any) => n.id);
      setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows([]);
  };

  const isSelected = (id: string) => selectedRows.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelected);
  };

  const { token } = useAppSelector((state) => state.user);

  const handleStatusChange = (
    id: string,
    status: CandidateStatusTransitionNames,
  ) => {
    dispatch(changeCandidateStatusThunk(token, { ids: [id], status }));
    setBulkMenuOpen(null);
  };

  const handleBulkStatusChange = (
    id: Array<string>,
    status: CandidateStatusTransitionNames,
  ) => {
    dispatch(changeCandidateStatusThunk(token, { ids: id, status }));
    setBulkMenuOpen(null);
  };

  const handleEnrichingByButtonClick = (id: string) => {
    setSelectedRows([id]);
    setExtraInformationOpen(true);
  };
  
  const closeExtraIfoModal = () => setExtraInformationOpen(false);
  const closeMessages = () => setAnchorMessages(null);
  const closeStatusModal = () => setStatusModalOpen(false);

  useEffect(() => {
    dispatch(getCandidatesThunk(token, leadId));
  }, [dispatch]);

  return (
    <>
      {!isLoading ? (
        <>
          <TableContainer className={classes.tableContainer}>
            <div className={classes.tableHeader}>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  checked={
                    candidatesArray.length > 0 &&
                    selectedRows.length === candidatesArray.length
                  }
                  onChange={handleSelectAllClick}
                />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    setBulkMenuOpen(event.currentTarget)
                  }
                  disabled={!selectedRows.length}
                  key={`${selectedRows.length}`}
                  variant="outlined"
                  className={bulkMenuOpen ? classes.activeBtn : undefined}
                  endIcon={
                    bulkMenuOpen ? (
                      <KeyboardArrowUpOutlined />
                    ) : (
                      <KeyboardArrowDownOutlined />
                    )
                  }>
                  Bulkacties
                </Button>
                <BulkActionsPopover
                  handleBulkStatusChange={handleBulkStatusChange}
                  messages={false}
                  selectedRows={{ candidates: selectedRows }}
                  handleClose={setBulkMenuOpen}
                  openExtraInformation={() => setExtraInformationOpen(true)}
                  openMessages={setAnchorMessages}
                  anchorEl={bulkMenuOpen}
                  openStatusModal={() => setStatusModalOpen(true)}
                />
              </div>
              <div className={classes.selectContainer}>
                <FormControl>
                  <Select
                    onChange={handleSortingChange}
                    value={sorting}
                    id="sorting-select-input"
                    disableUnderline>
                    <MenuItem value=".">Ongesorteerd</MenuItem>
                    <MenuItem value="income.desc">
                      Inkomen hoog &gt; laag
                    </MenuItem>
                    <MenuItem value="income.asc">
                      Inkomen laag &gt; hoog
                    </MenuItem>
                    {/* <MenuItem value="inquiry_date.desc">
                    Aanmelding nieuw &gt; oud
                  </MenuItem>
                  <MenuItem value="inquiry_date.asc">
                    Aanmelding oud &gt; nieuw
                  </MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </div>
            {candidatesArray.length ? (
              <Table>
                <TableBody>
                  {candidatesArray.map((candidate: any) => {
                    const isItemSelected = isSelected(candidate.id);
                    return (
                      <TableRow
                        key={candidate.id}
                        hover
                        role="checkbox"
                        selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) =>
                              handleClick(event, candidate.id)
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            history.push(`/dashboard/${leadId}/${candidate.id}`)
                          }>
                          <Typography
                            style={{ fontWeight: 'bold' }}
                            variant="body1">
                            {candidate.firstName}{' '}
                            {candidate.firstName !== candidate.lastName &&
                              candidate.lastName}
                          </Typography>
                        </TableCell>
                        <CandidatesDataCell
                          handleEnrichingByButtonClick={
                            handleEnrichingByButtonClick
                          }
                          closeExtraInfoModal={closeExtraIfoModal}
                          setSelectedRows={setSelectedRows}
                          setExtraInformationOpen={setExtraInformationOpen}
                          candidate={candidate}
                        />
                        <TableCell align="right">
                          <StatusButton
                            onClick={() =>
                              handleStatusChange(
                                candidate.id,
                                candidate.status === CandidateStatus.suitable
                                  ? CandidateStatusTransitionNames.reset
                                  : CandidateStatusTransitionNames.promote,
                              )
                            }
                            selected={
                              candidate.status === CandidateStatus.suitable
                            }>
                            <ThumbUpOutlinedIcon />
                          </StatusButton>
                          <StatusButton
                            onClick={() =>
                              handleStatusChange(
                                candidate.id,
                                candidate.status ===
                                  CandidateStatus.not_suitable
                                  ? CandidateStatusTransitionNames.reset
                                  : CandidateStatusTransitionNames.devote,
                              )
                            }
                            selected={
                              candidate.status === CandidateStatus.not_suitable
                            }>
                            <ThumbDownOutlinedIcon />
                          </StatusButton>
                          <StatusButton
                            onClick={() =>
                              handleStatusChange(
                                candidate.id,
                                candidate.status === CandidateStatus.rejected
                                  ? CandidateStatusTransitionNames.reset
                                  : CandidateStatusTransitionNames.reject,
                              )
                            }
                            selected={
                              candidate.status === CandidateStatus.rejected
                            }>
                            <ClearOutlinedIcon />
                          </StatusButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Typography
                className={classes.noCandidateMessage}
                variant="h6"
                align="center">
                Geen kandidaten om weer te geven
              </Typography>
            )}
            <ExtraInfoModal
              leadId={leadId}
              leadData={leadData}
              isOpen={extraInformationOpen}
              candidatesIds={selectedRows}
              handleClose={closeExtraIfoModal}
            />
            <BulkMessages
              candidatesIds={selectedRows}
              anchorEl={anchorMessages}
              handleClose={closeMessages}
              candidatesArray={candidatesArray}
            />
            <SetStatusModal
              isOpen={statusModalOpen}
              candidatesIds={selectedRows}
              handleClose={closeStatusModal}
            />
          </TableContainer>
          {filtersValues.pagination && (
            <TablePagination
              style={{ position: 'sticky', bottom: 0, background: '#fafafa' }}
              rowsPerPageOptions={[5, 10, 30]}
              component="div"
              count={count}
              rowsPerPage={itemsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};
