import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from '@material-ui/icons';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { setHeaderTab } from '../../../store/actions/uiActions';
import { useAppSelector } from '../../../store/store';
import { changeMessageStatusThunk } from '../../../store/thunks/candidatesThunk';
import { HeaderTab, MessagesStatus } from '../../../types/entityTypes';
import { BulkActionsPopover } from '../../pages/Dashboard/components/BulkActionsPopover/BulkActionsPopover';
import { BulkMessages } from '../../pages/Dashboard/components/BulkMessages/BulkMessages';
import { ExtraInfoModal } from '../../pages/Dashboard/components/ExtraInfoModal/ExtraInfoModal';
import { formatCandidateStatus } from '../../utils/formatCandidateStatus';
import { formatDate } from '../../utils/formatDate';
import { useStyles } from './styles';

interface Props {
  candidatesArray: any;
  isLoading: boolean;
  messages: any;
}

const MessagesTable: React.FC<Props> = ({
  candidatesArray,
  isLoading,
  messages,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const location = useLocation();
  const {token} = useAppSelector((state) => state.user)

  const history = useHistory();
  const { id: leadId } = useParams() as { id: string };

  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [bulkMenuOpen, setBulkMenuOpen] = useState<HTMLButtonElement | null>(
    null,
  );
  const [extraInformationOpen, setExtraInformationOpen] = useState(false);
  const [anchorMessages, setAnchorMessages] =
    useState<HTMLButtonElement | null>(null);

  const closeExtraIfoModal = () => setExtraInformationOpen(false);
  const closeMessages = () => setAnchorMessages(null);

  const isSelected = (id: string) => selectedRows.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    event.stopPropagation();
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: Array<string> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = messages.map((n: any) => n.id);
      setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows([]);
  };

  const handleMessageStatusChange = (
    event: React.MouseEvent<unknown>,
    ids: Array<string>,
    status: MessagesStatus,
  ) => {
    event.stopPropagation();
    dispatch(changeMessageStatusThunk(token, { ids, status }));
  };

  const handleRedirect = (id: string) => {
    history.push(`/dashboard/${leadId}/${id}`);
    dispatch(setHeaderTab(HeaderTab.CANDIDATE));
  };

  const formatMessageBody = (str: string) => {
    if (str.length < 75) {
      return str;
    }
    return `${str.slice(0, 75)}...`;
  };

  const formatBulkIds = () => {
    const candidates: Array<string> = [];

    messages.forEach((message: { id: string; to: { id: string } }) => {
      selectedRows.forEach((id) => {
        if (message.id === id) {
          candidates.push(message.to.id);
        }
      });
    });

    return { candidates, messages: selectedRows };
  };

  return (
    <>
      {!isLoading ? (
        <TableContainer className={classes.tableContainer}>
          <div className={classes.tableHeader}>
            <div className={classes.checkboxContainer}>
              <Checkbox
                checked={
                  messages.length > 0 && selectedRows.length === messages.length
                }
                onChange={handleSelectAllClick}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  setBulkMenuOpen(event.currentTarget)
                }
                disabled={!selectedRows.length}
                key={`${selectedRows.length}`}
                variant="outlined"
                className={bulkMenuOpen ? classes.activeBtn : undefined}
                endIcon={
                  bulkMenuOpen ? (
                    <KeyboardArrowUpOutlined />
                  ) : (
                    <KeyboardArrowDownOutlined />
                  )
                }>
                Bulkacties
              </Button>
              <BulkActionsPopover
                openStatusModal={() => setStatusOpen(true)}
                selectedRows={formatBulkIds()}
                handleClose={setBulkMenuOpen}
                openExtraInformation={() => setExtraInformationOpen(true)}
                openMessages={setAnchorMessages}
                anchorEl={bulkMenuOpen}
                messages
              />
            </div>
          </div>
          {candidatesArray.length ? (
            <Table>
              <TableBody>
                {messages.map((message: any) => {
                  const isItemSelected = isSelected(message.id);
                  const isUnread = message.status === MessagesStatus.unread;
                  return (
                    <TableRow
                      key={message.id}
                      hover
                      role="checkbox"
                      selected={isItemSelected}
                      className={!isUnread ? classes.grey : undefined}
                      onClick={() => handleRedirect(message.to.id)}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, message.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          className={isUnread ? classes.bold : undefined}>
                          {message.to.firstName} {message.to.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: '180px' }}>
                        <Typography variant="body1">
                          {formatCandidateStatus(message.to.status)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          component="span"
                          className={isUnread ? classes.bold : undefined}>
                          {message.subject}
                        </Typography>
                        {' - '}
                        <Typography variant="body1" component="span">
                          {formatMessageBody(message.body)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {isUnread ? (
                          <IconButton
                            onClick={(e) =>
                              handleMessageStatusChange(
                                e,
                                [message.id],
                                MessagesStatus.read,
                              )
                            }>
                            <EmailOutlinedIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={(e) =>
                              handleMessageStatusChange(
                                e,
                                [message.id],
                                MessagesStatus.unread,
                              )
                            }>
                            <DraftsOutlinedIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          className={isUnread ? classes.bold : undefined}>
                          {formatDate(message.created_at, {
                            day: 'numeric',
                            month: 'short',
                          })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography
              className={classes.noCandidateMessage}
              variant="h6"
              align="center">
              Geen berichten om weer te geven
            </Typography>
          )}
          <ExtraInfoModal
            isOpen={extraInformationOpen}
            candidatesIds={formatBulkIds().candidates}
            handleClose={closeExtraIfoModal}
          />
          <BulkMessages
            candidatesIds={formatBulkIds().candidates}
            anchorEl={anchorMessages}
            handleClose={closeMessages}
            candidatesArray={candidatesArray}
          />
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default MessagesTable;
function setStatusModalOpen(arg0: boolean): void {
  throw new Error('Function not implemented.');
}
function setStatusOpen(arg0: boolean): void {
  throw new Error('Function not implemented.');
}
