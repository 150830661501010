export interface AgentDetails {
  logo: string;
  privacyPolicy: string;
  url: string;
  name: string;
}

export interface ListingDetails {
  agentDetails: AgentDetails;
}


export interface AgentsItem {
  '@id': string
  '@type': string
  createdAt: string
  details: AgentItemDetails
  id: string
  listings: Array<ListingsItem>
  logins: Array<any>
  name: string
  systemName: string
  parariusOfficeKey: string
}
export interface AgentItemDetails {
  phone: string;
  name: string,
  city: string,
  url: string,
  postalCode: string,
  parariusId: string,
  house: string,
  logo: string,
  street: string,
  defaultEmail: string,
  defaultPhone: string,
  emailsForInquiries: Array<string>,
  leadsAgentIds: Array<string>
  parariusOfficeApiKey: string
  rejectionTemplateId: string
  emailTemplateId: string
  enrichmentTemplateId: string
  show_house_number: boolean
  isEmailSecure: boolean
  privacyPolicy: string
}
export interface ListingsItem {
  status: string,
  city: string;
  description: string;
  houseNumber: string;
  imageUrl: string;
  link: string;
  id: string;
  availableAt: string,
  details: ListingDetails;
  originId: string;
  originName: string;
  postalCode: string;
  price: number;
  showHouseNumber: boolean;
  rooms: number;
  street: string;
  streetWithHouseNumber: string;
  surface: number;
  candidates: Array<any>;
  availabilityInDays: number,
  onlinePeriodInDays: number,
  messages: Array<any>;
  listingId: string;
  numberOfLeads: number;
  numberOfUnassessedLeads: number;
  numberOfUnreadMessages: number;
  numberOfMessages: number;
  numberOfLeadsToAssess: number;
  numberOfSuitableLeads: number

}

export interface ListingError {
  error: boolean;
  errorMessage: string;
  code: number;
}

export enum CandidateStatus {
  suitable = 'suitable',
  not_suitable = 'not_suitable',
  rejected = 'rejected',
  assessment = 'assessment',
  none = 'none',
}

export enum MessagesStatus {
  unread = 'unread',
  read = 'read',
}

export enum Household {
  single = 'single',
  couple = 'with_partner',
  family = 'with_family',
  roommates = 'with_friends',
}

export interface Filters {
  income: string;
  pets: string | null;
  household: Array<string>;
  page: number;
  itemsPerPage: number;
  pagination: boolean;
  status?: string
}

export type SortDirection = 'desc' | 'asc';

export interface Sorting {
  sortBy: string;
  direction: SortDirection;
}

export type FiltersAndSorting = Filters & Sorting;

export interface FilterSortParams extends Filters {
  sort: string;
}

export enum HeaderTab {
  CANDIDATE,
  MESSAGES,
}

export enum MessageFilterTabsState {
  UNREAD = 'UNREAD',
  READ = 'READ',
  ALL = 'ALL',
}

export enum CandidatesFilterTabsState {
  SUITABLE = 'SUITABLE',
  NOT_SUITABLE = 'NOT_SUITABLE',
  REJECTED = 'REJECTED',
  NONE = 'NONE',
  ASSESSMENT = 'ASSESSMENT',
  ALL = 'ALL',
}
