import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';

interface Props {
  links: Array<{ href?: string; title: string }>;
}

export const Breadcrumbs: React.FC<Props> = ({ links }) => {
  const classes = useStyles();

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Link className={classes.link} to="/">
        Advertentieoverzicht
      </Link>
      {links.map((link) => (
        <>
          {link.href ? (
            <Link key={link.title} className={classes.link} to={link.href}>
              {link.title}
            </Link>
          ) : (
            <Typography key={link.title} className={classes.text}>
              {link.title}
            </Typography>
          )}
        </>
      ))}
    </MUIBreadcrumbs>
  );
};
