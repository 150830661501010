import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circle: {
      backgroundColor: '#EAEAEA',
      borderRadius: '50%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 444,
      zIndex: -1,
    },
    info: {
      borderRadius: 13,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px 50px 40px',
    },
    button: {
      backgroundColor: '#777777',
      color: '#fff',
      width: '100%',
      fontSize: 20,
      fontWeight: 'bold',
    },
    innerContainer: {
      position: 'relative',
      padding: 0,
    },
    title: {
      marginBottom: 16,
      fontWeight: 'bold',
      fontSize: 24,
    },
    subtitle: {
      marginBottom: 36,
      fontSize: 16,
    },
    mainTitle: {
      fontSize: 32,
      fontWeight: 'bold',
      position: 'relative',
      top: 110,
    },
  }),
);
