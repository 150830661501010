import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { setUserToken } from '../../../../../store/actions/userActions';
import { useAppSelector } from '../../../../../store/store';
import { getCandidatesThunk } from '../../../../../store/thunks/candidatesThunk';
import { Lead } from '../../../../../store/types/candidates';
import {
  CandidatesFilterTabsState,
  CandidateStatus,
  Filters,
} from '../../../../../types/entityTypes';
import { CandidatesTable } from '../CandidatesTable/CandidatesTable';
import { FiltersPopover } from '../FiltersPopover/FiltersPopover';
import { FilterTabs } from '../FilterTabs/FilterTabs';
import { useStyles } from './styles';

type Props = {
 leadData?: Lead
};

export const CandidatesTab: React.FC<Props> = ({
  leadData
}) => {
  const classes = useStyles();
  const { isLoading, data } = useAppSelector(
    (state) => state.candidates.candidatesList,
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const dispatch = useDispatch();

  const { id } = useParams() as { id: string };
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const location = useLocation() as { state?: { status: CandidateStatus } };

  const filterTabDefault = () => {

      if (location.state?.status === CandidateStatus.none) {
        return CandidatesFilterTabsState.NONE;
      }
  
      switch (location.state?.status) {
        case 'assessment':
          return CandidatesFilterTabsState.ASSESSMENT;
          case 'suitable':
          return CandidatesFilterTabsState.SUITABLE;
          case 'not_suitable':
          return CandidatesFilterTabsState.NOT_SUITABLE;
        default:
          return CandidatesFilterTabsState.NONE;
      }
    
   
  };

  const [filterTab, setFilterTab] = useState(filterTabDefault);
  const [filtersOpen, setFiltersOpen] = useState<HTMLButtonElement | null>(
    null,
  );
  const [filtersValues, setFiltersValues] = useState<Filters>({
    income: '',
    pets: null,
    household: [],
    page: 0,
    itemsPerPage: 30,
    pagination: true,
    status: filterTab

  });
  const [sortingValues, setSortingValues] = useState('income.desc');
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setFiltersValues({ ...filtersValues, page: newPage });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));

    setFiltersValues({...filtersValues, itemsPerPage: parseInt(event.target.value, 10)})
   
  };



  const handleFilterTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: CandidatesFilterTabsState,
  ) => {
    setPage(0)
    setSelectedRows([]);
    setFiltersValues({...filtersValues, page: 0, status: newValue})
    setFilterTab(newValue)
    
  };

  const handleFiltersClose = () => setFiltersOpen(null);
  const applyFilters = (values: Filters) => setFiltersValues(values);
  const applySorting = (value: string) => {
    setSortingValues(value);
  };

  const getFirstTruthyItem = (x: any) =>
    Object.keys(x).find((i) => x[i] === true);

  const renderCandidateCount = () => {
    switch (filterTab) {
      case 'NONE':
        return data.numberOfUnassessedLeads;
      case 'ASSESSMENT':
        return data.numberOfLeadsToAssess;
      case 'SUITABLE':
        return likedCandidates.length;
      case 'NOT_SUITABLE':
        return dislikedCandidates.length;
      case 'REJECTED':
        return canceledCandidates.length;
      case 'ALL':
        return data.numberOfLeads;
      default:
        return data.candidates.length;
    }
  };
  const {
    likedCandidates,
    dislikedCandidates,
    canceledCandidates,
    candidatesToJudge,
    candidatesToAssess,
  } = useMemo(
    () => ({
      likedCandidates: data.candidates.filter(
        (el) => el.status === CandidateStatus.suitable,
      ),
      dislikedCandidates: data.candidates.filter(
        (el) => el.status === CandidateStatus.not_suitable,
      ),
      canceledCandidates: data.candidates.filter(
        (el) => el.status === CandidateStatus.rejected,
      ),
      candidatesToJudge: data.candidates.filter(
        (el) => el.status === CandidateStatus.none,
      ),
      candidatesToAssess: data.candidates.filter(
        (el) => el.status === CandidateStatus.assessment,
      ),
    }),
    [data.candidates],
  );

  const candidatesTableData = useMemo(() => {
    switch (filterTab) {
      case CandidatesFilterTabsState.NONE:
        return candidatesToJudge;
      case CandidatesFilterTabsState.SUITABLE:
        return likedCandidates;
      case CandidatesFilterTabsState.NOT_SUITABLE:
        return dislikedCandidates;
      case CandidatesFilterTabsState.REJECTED:
        return canceledCandidates;
      case CandidatesFilterTabsState.ASSESSMENT:
        return candidatesToAssess;
      case CandidatesFilterTabsState.ALL:
        return data.candidates;
      default:
        return data.candidates;
    }
  }, [
    canceledCandidates,
    candidatesToJudge,
    data.candidates,
    dislikedCandidates,
    filterTab,
    likedCandidates,
    candidatesToAssess,
  ]);

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(setUserToken({ token: accessToken }));
          dispatch(
            getCandidatesThunk(accessToken, id, {
              ...filtersValues,
              sort: sortingValues,
            }),
        );
      } catch (e: any) {
        console.log(e.message);
      }
    };
    getUserToken();
  }, [
    dispatch,
    id,
    filtersValues,
    sortingValues,
    filterTab,
    page,
    rowsPerPage,
  ]);

  return (
    <>
      <Grid container spacing={3} className={classes.header}>
        <Grid item xs={9}>
          <FilterTabs
            toJudgeCount={data.numberOfUnassessedLeads}
            likedCount={data.numberOfSuitableLeads}
            dislikedCount={data.numberOfNotSuitableLeads}
            canceledCount={data.numberOfRejectedLeads}
            allCount={data.numberOfLeads}
            assessCount={data.numberOfLeadsToAssess}
            state={filterTab}
            handleChange={handleFilterTabChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={() => history.push(`/create-candidate/${id}`)}
            startIcon={<AddIcon />}
            variant="contained">
            Kandidaat toevoegen
          </Button>
        </Grid>
      </Grid>
      <div className={classes.filterBtnContainer}>
        <Button
          style={{ marginRight: 6 }}
          variant="outlined"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setFiltersOpen(event.currentTarget)
          }>
          Filters
        </Button>
        <FiltersPopover
          anchorEl={filtersOpen}
          handleClose={handleFiltersClose}
          values={filtersValues}
          applyFilters={applyFilters}
        />
      </div>
      {data.candidates.length && (
        <Typography variant="subtitle1" className={classes.candidateCount}>
          {renderCandidateCount()}{' '}
          {data.candidates.length > 1 ? 'kandidaten' : 'kandidaat'}
        </Typography>
      )}
      <CandidatesTable
        count={renderCandidateCount()}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        candidatesArray={candidatesTableData}
        isLoading={isLoading}
        applySorting={applySorting}
        sorting={sortingValues}
        page={page}
        itemsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        filtersValues={filtersValues}
        leadData={leadData}
      />
    </>
  );
};
